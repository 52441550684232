import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import log from "../Tools/Log";

const ChoiceDialog = ({
  title,
  message,
  open,
  onClose,
  onContinue,
  onDelete,
  choiceData,
  pagesAlreadyLinked,
}) => {
  const { t } = useTranslation();

  const [choice, setChoice] = useState({});

  useEffect(() => {
    log.trace("useEffect", pagesAlreadyLinked);

    if (choiceData && Object.keys(choiceData).length > 0) {
      setChoice(choiceData);
    } else {
      setChoice({}); // uuid: uuidv6() });
    }
  }, [open, choiceData]);

  const handleConfirmation = e => {
    log.trace("handleConfirmation", e);
    onContinue(choice);
  };

  const handleDialogClose = e => {
    log.trace("handleDialogClose", e);
    onClose(choice);
  };

  const handleDialogDelete = e => {
    log.trace("handleDialogDelete", e);
    onDelete(choice);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setChoice({ ...choice, [name]: value });
  };

  const handleFormSubmit = async e => {
    log.trace("handleFormSubmit", choice, choiceData);
    e.preventDefault();

    if (
      pagesAlreadyLinked.includes(choice.leadToPage) &&
      choiceData?.order == null
    ) {
      alert(t("error.pageAlreadyLinked"));
      return;
    }

    handleConfirmation();
  };

  return (
    <>
      {open && (
        <Dialog open={open} onClose={handleDialogClose}>
          <Box component="form" onSubmit={handleFormSubmit}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{message}</DialogContentText>
              <TextField
                autoFocus
                required
                margin="dense"
                id="text"
                name="text"
                label={t("text.choice")}
                type="text"
                fullWidth
                multiline
                value={choice.text}
                onChange={handleInputChange}
              />
              <TextField
                required
                margin="dense"
                id="leadToPage"
                name="leadToPage"
                label={t("view.choiceDialog.text.leadToPage")}
                type="number"
                fullWidth
                value={choice.leadToPage}
                onChange={handleInputChange}
                slotProps={{
                  input: {
                    readOnly: choiceData?.order != null,
                    disabled: choiceData?.order != null,
                  },
                }}
              />
            </DialogContent>
            <DialogActions sx={{ pr: 3, pb: 2 }}>
              <Button variant="contained" type="submit" color="primary">
                {t("buttonAction.continue")}
              </Button>
              <Button
                variant="outlined"
                onClick={handleDialogClose}
                color="secondary"
              >
                {t("buttonAction.cancel")}
              </Button>
              {choiceData?.order && (
                <IconButton
                  onClick={handleDialogDelete}
                  color="error"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </DialogActions>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default ChoiceDialog;
