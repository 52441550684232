import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { logEvent } from "./Services/firebase";
import log from "./Tools/Log";

function LandingPage() {
  const { t } = useTranslation();

  return (
    <Box component="section">
      <Typography variant="h2"></Typography>
      <Typography variant="h6"></Typography>
    </Box>
  );
}

export default LandingPage;
