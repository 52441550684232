// Import the functions you need from the SDKs you need
import { getAnalytics, logEvent as loge } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDkKkoYCjmjpiMOZVzZSbVKvhBLcboN-Jg",
  authDomain: "libell.us",
  projectId: "libell-us",
  storageBucket: "libell-us.appspot.com",
  messagingSenderId: "666234619898",
  appId: "1:666234619898:web:bc59a771f40f7854fcc643",
  measurementId: "G-TWR8KJ7857",
};

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(firebaseApp);
const firebaseStorage = getStorage();
const firebaseFunctions = getFunctions(firebaseApp);

function logEvent(eventName, data) {
  loge(firebaseAnalytics, eventName, data);
}

export { firebaseApp, firebaseFunctions, firebaseStorage, logEvent };
