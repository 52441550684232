import { logEvent } from "../Services/firebase";
import log from "./Log";

export function handleActionException(
  eventSource,
  exception,
  genericErrorMessage = "error.action"
) {
  return handleException(eventSource, exception, genericErrorMessage);
}

export function handleDataFetchException(
  eventSource,
  exception,
  genericErrorMessage = "error.dataFetch"
) {
  return handleException(eventSource, exception, genericErrorMessage);
}

function handleException(eventSource, exception, genericErrorMessage) {
  var message = genericErrorMessage;
  var isRedirect = false;
  var redirectUrl = null;

  log.error("API request error", exception);
  logEvent(eventSource, "api.exception", exception);

  if (exception.status == 310) {
    // TODO auto create profile?
    // 310 - not a member
    redirectUrl = "/newuserprofile";
    isRedirect = true;
  } else if (exception.status == 311) {
    // 311 - no membership
    redirectUrl = "/newusermembership";
    isRedirect = true;
  } else if (exception.status == 312) {
    // 312 - not allow-listed
    redirectUrl = "/betainvite";
    isRedirect = true;
  } else if (exception.status == 313) {
    // 313 - already subscribed
    redirectUrl = "/console";
    isRedirect = true;
  } else if (exception.status == 314) {
    // 314 - session paid; no membership created yet
    redirectUrl = "/subscribesuccess";
    isRedirect = true;
  } else if (exception.status == 401) {
    // 401 - unauthorized
    redirectUrl = "/login";
    isRedirect = true;
  } else if (exception.status == 403) {
    // 403 - forbidden (account suspended)
    message = "error.accountSuspension";

    //TODO FIXME should be a logout with a redirect to a static page
    // firebaseAuth.signOut().then(() => {
    //   navigate("/error");
    // });
  } else if (exception.status == 460) {
    message = "error.membershipGenerationLimitsReached";
  }

  return { isRedirect: isRedirect, redirectUrl: redirectUrl, message: message };
}
