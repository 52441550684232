import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { logEvent } from "../../Services/firebase";
import {
  copyDataToModel,
  formSubmitHeaders,
  requestHeaders,
} from "../../Tools/DataUtils";
import {
  handleActionException,
  handleDataFetchException,
} from "../../Tools/ErrorHandling";
import log from "../../Tools/Log";
import ImageGenerationDialog from "../../UiComponents/ImageGenerationDialog";
import ImagePicker from "../../UiComponents/ImagePicker";

function Location() {
  const eventSource = "Location";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [locationData, setLocationData] = useState({
    uuid: "",
    bookUuid: "",
    name: "",
    backstory: "",
    weather: null,
    sounds: null,
    lights: null,
    smells: null,
    sights: null,
    backgroundImage: "",
    newBackgroundImage: null,
  });
  const [creatingNew, setCreatingNew] = useState(false);
  const [openImageGenerationDialog, setOpenImageGenerationDialog] =
    useState(false);
  const [imageGenerationOptions, setImageGenerationOptions] = useState({
    imageService: {
      name: `${process.env.REACT_APP_DEFAULT_AI_IMAGE_SERVICE}`,
      endpoint: `${process.env.REACT_APP_DEFAULT_AI_IMAGE_SERVICE_ENDPOINT}`,
    },
    useLocation: true,
    useCharacters: true,
    useImageDescriptorService: true,
  });
  const [openImagePickerDialog, setOpenImagePickerDialog] = useState(false);
  const [imagePickerOptions, setImagePickerOptions] = useState({});
  const [locationAmbianceOptions, setLocationAmbianceOptions] = useState([]);
  const [ambianceList, setAmbianceList] = useState();
  const [selectedAmbiance, setSelectedAmbiance] = useState();

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    if (pageParams.lid == "new") {
      log.trace("creating new");
      setCreatingNew(true);
      setLocationData({ ...locationData, bookUuid: pageParams.id });
    }

    fetchData();
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      requests.push(fetchAmbianceOptions());

      if (pageParams.lid != "new") {
        requests.push(
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/getLocations`,
              {
                bookUuid: pageParams.id,
                filters: { locationUuid: pageParams.lid },
              },
              headers
            )
            .then(response => {
              log.trace("fetchData.response.data", response.data);
              logEvent(eventSource, "fetchData.response");

              response.data.map(item => {
                item.imageUrl =
                  `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                  item.backgroundImage;
              });

              if (response.data[0]) {
                setLocationData(response.data[0]);
                response.data[0].ambiance &&
                  response.data[0].ambiance.length > 0 &&
                  setLocationAmbianceOptions(response.data[0].ambiance);
              }
            })
        );
      }

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleDataFetchException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  const fetchAmbianceOptions = async () => {
    log.trace("fetchAmbianceOptions.request");
    logEvent(eventSource, "fetchAmbianceOptions.request");

    try {
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/getAmbianceOptions`,
            {},
            headers
          )
          .then(response => {
            log.trace("fetchData.response.data", response.data);
            logEvent(eventSource, "fetchData.response");

            if (response.data) {
              const ambiances = response.data;
              ambiances.map(item => {
                item.label = t(
                  `view.locationAmbianceSetupDialog.text.${item.key}`
                );
              });

              setAmbianceList({
                options: response.data.sort((a, b) =>
                  a.label.localeCompare(b.label)
                ),
              });
            }
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleDataFetchException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleFormSubmit = async e => {
    log.trace("handleFormSubmit.request", e, locationData);
    logEvent(eventSource, "handleFormSubmit.request");

    try {
      e.preventDefault();
      showLoadingUI();

      const requests = [];
      const headers = await formSubmitHeaders();

      const formData = new FormData();
      formData.append("bookUuid", pageParams.id);
      if (pageParams.lid != "new") formData.append("uuid", pageParams.lid);
      locationAmbianceOptions &&
        formData.append("ambiance", JSON.stringify(locationAmbianceOptions));
      copyDataToModel(locationData, formData);

      if (creatingNew) {
        requests.push(
          axios.post(
            `${process.env.REACT_APP_API_URL}/putLocation`,
            formData,
            headers
          )
        );
      } else {
        requests.push(
          axios.put(
            `${process.env.REACT_APP_API_URL}/putLocation`,
            formData,
            headers
          )
        );
      }

      await Promise.all(requests)
        .then(response => {
          log.trace("handleFormSubmit.response.data", response.data);
          logEvent(eventSource, "handleFormSubmit.response");

          setLocationData({});
          navigate(`/console/books/${pageParams.id}/locations`);
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      const response = handleActionException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setLocationData({ ...locationData, [name]: value });
  };

  const handleFileChange = e => {
    if (e.target.files[0]) {
      URL.revokeObjectURL(locationData.imageUrl);
      const previewImageUrl = URL.createObjectURL(e.target.files[0]);

      setLocationData({
        ...locationData,
        newBackgroundImage: e.target.files[0],
        backgroundImage: e.target.files[0].name,
        imageUrl: previewImageUrl,
        imagePrompt: null,
      });
    }
  };

  const handleCancel = () => {
    log.trace("handleCancel");
    navigate(`/console/books/${pageParams.id}/locations`);
  };

  const handlePageBackgroundImageLoaded = e => {
    log.trace("handlePageBackgroundImageLoaded", e);
    // const img = e.target;
    // TODO warn of best options for image format, ration, etc
    // BE uses sharp; converts to web friendly format automatically
  };

  const suggestBackgroundImageFromDescription = async selectedOptionsData => {
    log.trace(
      "suggestBackgroundImageFromDescription.request",
      selectedOptionsData
    );
    logEvent(eventSource, "suggestBackgroundImageFromDescription.request");

    if (!locationData.backstory || locationData.backstory.length == 0) {
      setAlertMessage({
        message: t("error.suggesLocationBackgroundImageMissingParameters"),
        severity: "error",
      });

      return;
    }

    showLoadingUI();

    const requests = [];
    const headers = await requestHeaders();

    // TODO priority this should be a specific endpoint for this functionality; no client side logic
    // genai to produce bg image
    try {
      const locationlDescription = [
        "Generate a landscape image for a location with the following characteristics. ",
      ];

      locationData.backstory &&
        locationlDescription.push(locationData.backstory + ".");
      locationData.sights &&
        locationlDescription.push(
          "With the following landmarks: " + locationData.sights + "."
        );
      locationData.weather &&
        locationlDescription.push(
          "With the weather elements: " + locationData.weather + "."
        );
      locationlDescription.push(
        "With sounds and noise as: " + locationData.sounds + "."
      );
      locationlDescription.push(
        "With smells like: " + locationData.smells + "."
      );
      locationlDescription.push(
        "With light setup as: " + locationData.lights + "."
      );

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/${selectedOptionsData.imageService.endpoint}`,
            {
              bookUuid: pageParams.id,
              narrativePrompt: locationlDescription.join(" "),
              options: selectedOptionsData,
            },
            headers
          )
          .then(response => {
            log.trace(
              "suggestBackgroundImageFromDescription.response.data",
              response.data
            );
            logEvent(
              eventSource,
              "suggestBackgroundImageFromDescription.response"
            );

            const imageUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
              response.data.filePath;

            //set form data with image generated. image is already stored, so just save the file path
            setLocationData({
              ...locationData,
              imagePrompt: response.data.imagePrompt,
              backgroundImage: response.data.filePath,
              imageUrl: imageUrl,
            });
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleActionException(
        eventSource,
        exception,
        "error.imageGenerationGeneric"
      );
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  const handleOpenImageGenerationDialog = () => {
    log.trace("handleOpenImageGenerationDialog");
    logEvent(eventSource, "handleOpenImageGenerationDialog");

    setImageGenerationOptions({
      ...imageGenerationOptions,
      useCharacters: "hidden",
      useLocation: "hidden",
    });

    setOpenImageGenerationDialog(true);
  };

  const handleCloseImageGenerationDialog = selectedOptionsData => {
    log.trace("handleCloseImageGenerationDialog", selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);

    setOpenImageGenerationDialog(false);
  };

  const handleContinueImageGeneration = async selectedOptionsData => {
    log.trace("handleContinueImageGeneration", selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);
    setOpenImageGenerationDialog(false);

    suggestBackgroundImageFromDescription(selectedOptionsData);
  };

  const handleOpenImagePickerDialog = () => {
    log.trace("handleOpenImagePickerDialog");
    setOpenImagePickerDialog(true);
  };

  const handleCloseImagePickerDialog = selectedOptionsData => {
    log.trace("handleCloseImagePickerDialog", selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);
    setOpenImagePickerDialog(false);
  };

  const handleContinueImagePicker = async selectedOptionsData => {
    log.trace("handleContinueImagePicker", selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);
    setOpenImagePickerDialog(false);

    //from computer or from gallery, ...
    if (!selectedOptionsData) {
      log.trace("handleContinueImagePicker.fromComputer");
      logEvent(eventSource, "handleContinueImagePicker.fromComputer");
      imagePickerSelectFromComputer();
    } else {
      log.trace("handleContinueImagePicker.fromGallery");
      logEvent(eventSource, "handleContinueImagePicker.fromGallery");
      const filePath = selectedOptionsData.filePath;
      const imagePrompt = selectedOptionsData.imagePrompt;
      const imageUrl = selectedOptionsData.imageUrl;

      //set form data with image previously generated. image is already stored, so just get the file path
      setLocationData({
        ...locationData,
        imagePrompt: imagePrompt,
        backgroundImage: filePath,
        imageUrl: imageUrl,
        newBackgroundImage: null,
      });
    }
  };

  const imagePickerSelectFromComputer = () => {
    log.trace("imagePickerSelectFromComputer");
    uploadInputRef.current && uploadInputRef.current.click();
  };

  const suggestAmbianceSetupFromDetails = async () => {
    log.trace("suggestAmbianceSetupFromDetails.request");
    logEvent(eventSource, "suggestAmbianceSetupFromDetails.request");

    if (!locationData.backstory || locationData.backstory.length == 0) {
      setAlertMessage({
        message: t("error.suggestAmbianceSetupFromDetails"),
        severity: "error",
      });

      return;
    }

    showLoadingUI();

    const requests = [];
    const headers = await requestHeaders();

    try {
      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/suggestLocationAmbiance`,
            {
              bookUuid: pageParams.id,
              location: locationData,
            },
            headers
          )
          .then(response => {
            log.trace(
              "suggestAmbianceSetupFromDetails.response.data",
              response.data
            );
            logEvent(eventSource, "suggestAmbianceSetupFromDetails.response");

            setLocationAmbianceOptions(response.data);
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleActionException(
        eventSource,
        exception,
        "error.ambianceGenerationGeneric"
      );
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  const handleAmbianceInputChange = (e, value) => {
    log.warn("handleAmbianceInputChange", e, value);

    setAlertMessage({});
    const transformedValue = value.map(item => ({
      key: item.key,
      question: item.question,
      reasoning: item.reasoning,
    }));
    setLocationAmbianceOptions(transformedValue);
  };

  const hasAmbianceReasoning = key => {
    const ambiance = locationAmbianceOptions.find(item => item.key === key);
    log.trace("hasAmbianceReasoning", key, ambiance);
    return ambiance?.reasoning != null;
  };

  const handleAmbianceClick = (e, value) => {
    log.trace("handleAmbianceClick", value);

    if (value.reasoning) {
      setSelectedAmbiance(value);
    } else {
      setSelectedAmbiance({
        ...value,
        reasoning: t("view.locationAmbianceSetupDialog.text.noReasoning"),
      });
    }
  };

  const handleRemoveImage = () => {
    log.trace("handleRemoveImage");
    setLocationData({
      ...locationData,
      backgroundImage: "",
      imageUrl: "",
      newBackgroundImage: null,
    });
  };

  const uploadInputRef = useRef(null);

  return (
    <PageContainer
      title=""
      breadcrumbs={[
        { title: t("text.overview"), path: `/console/books/${pageParams.id}` },
        {
          title: t("text.locations"),
          path: `/console/books/${pageParams.id}/locations`,
        },
        {
          title: t("text.location"),
          path: `/console/books/${pageParams.id}/locations/${pageParams.lid}`,
        },
      ]}
    >
      <Box component="section">
        {alertMessage.message && (
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        )}

        {loading ? (
          <LinearProgress />
        ) : (
          <Box component="section" sx={{ p: 2 }}>
            <ImageGenerationDialog
              title={t("imageGenerationDialog.title")}
              message={t("imageGenerationDialog.message")}
              open={openImageGenerationDialog}
              onClose={handleCloseImageGenerationDialog}
              onContinue={handleContinueImageGeneration}
              optionsData={imageGenerationOptions}
            />

            <ImagePicker
              title={t("imagePicker.title")}
              message={t("imagePicker.message")}
              open={openImagePickerDialog}
              onClose={handleCloseImagePickerDialog}
              onContinue={handleContinueImagePicker}
              optionsData={imagePickerOptions}
            />

            <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 1 }}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="location-details-content"
                  id="location-details-header"
                >
                  {t("header.locationDetails")}
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2} direction="column" sx={{ p: 2 }}>
                    <TextField
                      id="name"
                      name="name"
                      required
                      autoFocus
                      value={locationData.name}
                      onChange={handleInputChange}
                      label={t("text.name")}
                      variant="outlined"
                    />
                    <TextField
                      id="backstory"
                      name="backstory"
                      required
                      multiline
                      value={locationData.backstory}
                      onChange={handleInputChange}
                      label={t("text.backstory")}
                      variant="outlined"
                    />
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="location-details-content"
                  id="location-details-header"
                >
                  {t("header.expandedDetails")}
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2} direction="column" sx={{ p: 2 }}>
                    <TextField
                      id="sights"
                      name="sights"
                      value={locationData.sights}
                      onChange={handleInputChange}
                      label={t("view.location.text.sights")}
                      variant="outlined"
                    />
                    <TextField
                      id="weather"
                      name="weather"
                      value={locationData.weather}
                      onChange={handleInputChange}
                      label={t("view.location.text.weather")}
                      variant="outlined"
                    />
                    <TextField
                      id="sounds"
                      name="sounds"
                      value={locationData.sounds}
                      onChange={handleInputChange}
                      label={t("view.location.text.sounds")}
                      variant="outlined"
                    />
                    <TextField
                      id="smells"
                      name="smells"
                      value={locationData.smells}
                      onChange={handleInputChange}
                      label={t("view.location.text.smells")}
                      variant="outlined"
                    />
                    <TextField
                      id="lights"
                      name="lights"
                      value={locationData.lights}
                      onChange={handleInputChange}
                      label={t("view.location.text.lights")}
                      variant="outlined"
                    />
                    <Stack
                      spacing={2}
                      direction="row"
                      sx={{ p: 2, justifyContent: "center" }}
                    >
                      <Button
                        variant="text"
                        onClick={handleOpenImageGenerationDialog}
                        startIcon={<AddPhotoAlternateIcon />}
                      >
                        {t("buttonAction.suggestLocationImage")}
                      </Button>
                    </Stack>
                  </Stack>
                </AccordionDetails>
              </Accordion>

              {ambianceList && (
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="location-details-content"
                    id="location-details-header"
                  >
                    {t("header.ambiance")}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack direction="column" spacing={2}>
                      {selectedAmbiance && (
                        <>
                          <Typography variant="body2">
                            {t(
                              `view.locationAmbianceSetupDialog.text.${selectedAmbiance.key}`
                            )}
                          </Typography>
                          <Typography variant="body2">
                            {selectedAmbiance.reasoning}
                          </Typography>
                        </>
                      )}

                      <Autocomplete
                        {...ambianceList}
                        isOptionEqualToValue={(option, value) =>
                          option.key === value.key
                        }
                        id="ambiance-auto-complete"
                        name="ambiance-auto-complete"
                        fullWidth
                        autoComplete
                        multiple
                        filterSelectedOptions
                        onChange={handleAmbianceInputChange}
                        value={locationAmbianceOptions}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { key, ...tagProps } = getTagProps({ index });

                            return (
                              <Chip
                                variant={
                                  hasAmbianceReasoning(option.key)
                                    ? "filled"
                                    : "outlined"
                                }
                                label={t(
                                  `view.locationAmbianceSetupDialog.text.${option.key}`
                                )}
                                key={key}
                                onClick={e => handleAmbianceClick(e, option)}
                                {...tagProps}
                              />
                            );
                          })
                        }
                        renderInput={ambiance => (
                          <TextField
                            {...ambiance}
                            key={ambiance.value}
                            variant="standard"
                            label={t("text.ambiance")}
                          />
                        )}
                      />
                      <Stack
                        spacing={2}
                        direction="row"
                        sx={{ p: 2, justifyContent: "center" }}
                      >
                        <Button
                          variant="text"
                          onClick={suggestAmbianceSetupFromDetails}
                          startIcon={<AutoFixHighIcon />}
                        >
                          {t("buttonAction.suggestAmbianceSetup")}
                        </Button>
                      </Stack>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              )}
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="images-content"
                  id="images-header"
                >
                  {t("header.media")}
                </AccordionSummary>
                <AccordionDetails>
                  <Box component="section" sx={{ p: 2 }}>
                    <Stack
                      direction="row"
                      justifyContent={"center"}
                      spacing={2}
                    >
                      <Button
                        onClick={handleOpenImagePickerDialog}
                        variant="contained"
                      >
                        {t("buttonAction.selectLocationImage")}
                      </Button>
                      {locationData.backgroundImage && (
                        <IconButton
                          onClick={handleRemoveImage}
                          color="error"
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Stack>

                    {locationData.backgroundImage && (
                      <Box sx={{ pt: 2, pb: 2 }}>
                        <Avatar
                          alt={locationData.backgroundImage}
                          src={locationData.imageUrl}
                          variant="square"
                          sx={{ width: "100%", height: "30%" }}
                          onLoad={handlePageBackgroundImageLoaded}
                        />
                        <Typography
                          variant="body2"
                          id="backgroundImage"
                          name="backgroundImage"
                          sx={{ textAlign: "right" }}
                        >
                          {locationData.backgroundImage}
                        </Typography>
                      </Box>
                    )}
                    <input
                      id="newBackgroundImage"
                      name="newBackgroundImage"
                      ref={uploadInputRef}
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Stack spacing={2} direction="row" justifyContent={"center"}>
                <Button variant="contained" type="submit" color="primary">
                  {t("buttonAction.save")}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancel}
                >
                  {t("buttonAction.cancel")}
                </Button>
              </Stack>
            </Box>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

export default Location;
