import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  Item,
  Menu,
  Separator,
  Submenu,
  useContextMenu,
} from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { logEvent } from "../../Services/firebase";
import {
  copyDataToModel,
  formSubmitHeaders,
  requestHeaders,
} from "../../Tools/DataUtils";
import {
  handleActionException,
  handleDataFetchException,
} from "../../Tools/ErrorHandling";
import log from "../../Tools/Log";
import AudioGenerationDialog from "../../UiComponents/AudioGenerationDialog";
import ChoiceDialog from "../../UiComponents/ChoiceDialog";
import ImageGenerationDialog from "../../UiComponents/ImageGenerationDialog";
import ImagePicker from "../../UiComponents/ImagePicker";
import TextGenerationDialog from "../../UiComponents/TextGenerationDialog";

function Page() {
  const eventSource = "Page";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [bookData, setBookData] = useState({});
  const [pageData, setPageData] = useState({});
  const [creatingNew, setCreatingNew] = useState(false);
  const [openPreview, setOpenPreview] = React.useState(false);
  const [charactersList, setCharactersList] = useState({ options: [] });
  const [locationsList, setLocationsList] = useState({ options: [] });

  const [openTextGenerationDialog, setOpenTextGenerationDialog] =
    useState(false);
  const [textGenerationOptions, setTextGenerationOptions] = useState({
    useLocation: true,
    useCharacters: true,
    usePreviousPagesContext: true,
    userInclusions: "",
    userExclusions: "",
  });

  const [openImageGenerationDialog, setOpenImageGenerationDialog] =
    useState(false);
  const [imageGenerationOptions, setImageGenerationOptions] = useState({
    imageService: {
      name: `${process.env.REACT_APP_DEFAULT_AI_IMAGE_SERVICE}`,
      endpoint: `${process.env.REACT_APP_DEFAULT_AI_IMAGE_SERVICE_ENDPOINT}`,
    },
    useLocation: true,
    useCharacters: true,
    useImageDescriptorService: true,
  });

  const [openAudioGenerationDialog, setOpenAudioGenerationDialog] =
    useState(false);
  const [audioGenerationOptions, setAudioGenerationOptions] = useState();

  const [choice, setChoice] = useState({});
  const [openChoiceDialog, setOpenChoiceDialog] = useState(false);

  const [openImagePickerDialog, setOpenImagePickerDialog] = useState(false);
  const [imagePickerOptions, setImagePickerOptions] = useState({});

  const CONTEXT_MENU_ID = "editor-context-menu";
  const { show } = useContextMenu({
    id: CONTEXT_MENU_ID,
  });

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    if (pageParams.pid == "new") {
      log.trace("creating new");
      setCreatingNew(true);
      setPageData({ ...pageData, bookUuid: pageParams.id });
    }

    fetchData(pageParams.pid == "new");
  }, []);

  const fetchData = async isNewPage => {
    log.trace("fetchData.request", isNewPage, pageParams.pid);
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      // TODO implement filters
      const filters = { pageUuid: pageParams.pid };

      const requests = [];
      const headers = await requestHeaders();

      // load page if not creating a new one
      if (!isNewPage) {
        requests.push(
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/getPages`,
              {
                bookUuid: pageParams.id,
                filters,
              },
              headers
            )
            .then(response => {
              log.trace("fetchData.response.data.getPages", response.data);
              logEvent(eventSource, "fetchData.response");

              response.data.map(item => {
                item.backgroundImage &&
                  (item.imageUrl =
                    `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                    item.backgroundImage);

                item.narrativeAudio &&
                  (item.audioUrl =
                    `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                    item.narrativeAudio);
              });

              const page = response.data[0];
              if (page) {
                // simplify UX by using the expanded model; reduce to uuid on submit
                if (page.location) page.location = page.metadata["location"];
                if (page.characters)
                  page.characters = page.metadata["characters"];

                setPageData(page);
              }
            })
        );
      }

      // load characters dropdown data
      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/getCharacters`,
            {
              bookUuid: pageParams.id,
              filters,
            },
            headers
          )
          .then(response => {
            log.trace("fetchData.response.data.getCharacters", response.data);
            logEvent(eventSource, "fetchData.response");

            response.data.map(item => {
              item.imageUrl =
                `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                item.backgroundImage;
            });

            if (response.data && response.data.length > 0) {
              setCharactersList({
                options: response.data,
                getOptionLabel: option => option.name,
                isOptionEqualToValue: (option, value) =>
                  option.uuid === value.uuid,
              });
            } else {
              log.trace("No characters available; useCharacters false");
              setImageGenerationOptions({
                ...imageGenerationOptions,
                useCharacters: false,
              });
            }
          })
      );

      // load locations dropdown data
      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/getLocations`,
            {
              bookUuid: pageParams.id,
              filters,
            },
            headers
          )
          .then(response => {
            log.trace("fetchData.response.data.getLocations", response.data);
            logEvent(eventSource, "fetchData.response");

            response.data.map(item => {
              item.imageUrl =
                `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                item.backgroundImage;
            });

            if (response.data && response.data.length > 0) {
              setLocationsList({
                options: response.data,
                getOptionLabel: option => option.name,
                isOptionEqualToValue: (option, value) =>
                  option.uuid === value.uuid,
              });
            } else {
              log.trace("No location available; useLocation false");
              setImageGenerationOptions({
                ...imageGenerationOptions,
                useLocation: false,
              });
            }
          })
      );

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/getBooks`,
            { filters: { bookUuid: pageParams.id } },
            headers
          )
          .then(response => {
            log.trace("fetchData.response.data.getBooks", response.data);
            logEvent(eventSource, "fetchData.response");

            response.data.map(item => {
              item.imageUrl =
                `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                item.coverImage;
            });

            setBookData(response.data[0]);
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleDataFetchException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleFormSubmit = async e => {
    log.trace("handleFormSubmit.request", e, pageData);
    logEvent(eventSource, "handleFormSubmit.request");

    try {
      e.preventDefault();
      showLoadingUI();

      const requests = [];
      const headers = await formSubmitHeaders();

      const formData = new FormData();
      formData.append("bookUuid", pageParams.id);
      if (pageParams.pid != "new") formData.append("uuid", pageParams.pid);
      if (pageData.location)
        formData.append("location", pageData.location.uuid);
      if (pageData.characters)
        formData.append(
          "characters",
          pageData.characters.map(character => character.uuid)
        );
      copyDataToModel(pageData, formData, ["metadata"]);

      if (creatingNew) {
        requests.push(
          axios.post(
            `${process.env.REACT_APP_API_URL}/putPage`,
            formData,
            headers
          )
        );
      } else {
        requests.push(
          axios.put(
            `${process.env.REACT_APP_API_URL}/putPage`,
            formData,
            await formSubmitHeaders()
          )
        );
      }

      await Promise.all(requests)
        .then(response => {
          log.trace("handleFormSubmit.response.data", response.data);
          logEvent(eventSource, "handleFormSubmit.response");
          navigate(`/console/books/${pageParams.id}/pages`);
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      const response = handleActionException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setPageData({ ...pageData, [name]: value });
  };

  const handleFileChange = e => {
    if (e.target.files[0]) {
      URL.revokeObjectURL(pageData.imageUrl);
      const previewImageUrl = URL.createObjectURL(e.target.files[0]);

      setPageData({
        ...pageData,
        newBackgroundImage: e.target.files[0],
        backgroundImage: e.target.files[0].name,
        imageUrl: previewImageUrl,
        imagePrompt: null,
      });
    }
  };

  const handleCancel = () => {
    log.trace("handleCancel");

    navigate(`/console/books/${pageParams.id}/pages`);
  };

  const handlePageBackgroundImageLoaded = e => {
    log.trace("handlePageBackgroundImageLoaded", e);
    // const img = e.target;
    // TODO warn of best options for image format, ration, etc
    // BE uses sharp; converts to web friendly format automatically
  };

  const suggestPageNarrativeFromIdeation = async selectedOptionsData => {
    log.trace("suggestPageNarrativeFromIdeation.request", selectedOptionsData);
    logEvent(eventSource, "suggestPageNarrativeFromIdeation.request");

    if (!pageData.ideation || pageData.ideation.length == 0) {
      setAlertMessage({
        message: t("error.suggestPageNarrativeMissingParameters"),
        severity: "error", //success info warning error
      });

      return;
    }

    showLoadingUI();

    const requests = [];
    const headers = await requestHeaders();

    try {
      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/suggestNarrativeBasedOnIdeation`,
            {
              bookUuid: pageParams.id,
              ideationPrompt: pageData.ideation,
              location: pageData.location,
              characters: pageData.characters,
              options: selectedOptionsData,
            },
            headers
          )
          .then(generatedText => {
            log.trace(
              "suggestPageNarrativeFromIdeation.response.data",
              generatedText.data
            );
            logEvent(eventSource, "suggestPageNarrativeFromIdeation.response");

            setPageData({ ...pageData, narrative: generatedText.data.text });
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleActionException(
        eventSource,
        exception,
        "error.pageNarrativeGeneric"
      );
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error", //success info warning error
      });

      hideLoadingUI();
    }
  };

  const suggestBackgroundImageFromPageNarrative = async selectedOptionsData => {
    log.trace(
      "suggestBackgroundImageFromPageNarrative.request",
      selectedOptionsData
    );
    logEvent(eventSource, "suggestBackgroundImageFromPageNarrative.request");

    if (
      (!pageData.ideation || pageData.ideation.length == 0) &&
      (!pageData.narrative || pageData.narrative.length == 0)
    ) {
      setAlertMessage({
        message: t("error.suggestBackgroundImageMissingParameters"),
        severity: "error", //success info warning error
      });

      return;
    }

    showLoadingUI();

    const requests = [];
    const headers = await requestHeaders();

    try {
      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/${selectedOptionsData.imageService.endpoint}`,
            {
              bookUuid: pageParams.id,
              narrativePrompt: pageData.narrative ?? pageData.ideation,
              location: pageData.location,
              characters: pageData.characters,
              options: selectedOptionsData,
            },
            headers
          )
          .then(response => {
            log.trace(
              "suggestBackgroundImageFromPageNarrative.response.data",
              response.data
            );
            logEvent(
              eventSource,
              "suggestBackgroundImageFromPageNarrative.response"
            );

            const imageUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
              response.data.filePath;

            //set form data with image generated. image is already stored, so just save the file path
            setPageData({
              ...pageData,
              imagePrompt: response.data.imagePrompt,
              backgroundImage: response.data.filePath,
              imageUrl: imageUrl,
            });
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleActionException(
        eventSource,
        exception,
        "error.imageGenerationGeneric"
      );
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error", //success info warning error
      });

      hideLoadingUI();
    }
  };

  const suggestPageNarrationAudio = async selectedOptionsData => {
    log.trace("suggestPageNarrationAudio.request", selectedOptionsData);
    logEvent(eventSource, "suggestPageNarrationAudio.request");

    try {
      if (!pageData.narrative || pageData.narrative.length == 0) {
        setAlertMessage({
          message: t("error.suggestPageNarrationAudioMissingParameters"),
          severity: "error", //success info warning error
        });

        return;
      }

      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/suggestNarrationBasedOnNarrative`,
            {
              bookUuid: pageParams.id,
              narrativePrompt: pageData.narrative,
              options: selectedOptionsData,
            },
            headers
          )
          .then(generatedAudioFilePath => {
            log.trace(
              "suggestPageNarrationAudio.response.data",
              generatedAudioFilePath.data
            );
            logEvent(eventSource, "suggestPageNarrationAudio.response");

            const audioUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
              generatedAudioFilePath.data;

            //set form data with image generated. image is already stored, so just save the file path
            setPageData({
              ...pageData,
              narrativeAudio: generatedAudioFilePath.data,
              audioUrl: audioUrl,
              audioVoiceType: selectedOptionsData.voiceType,
            });
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleActionException(
        eventSource,
        exception,
        "error.audioGenerationGeneric"
      );
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error", //success info warning error
      });

      hideLoadingUI();
    }
  };

  const removePageNarrationAudio = async () => {
    log.trace("removePageNarrationAudio");
    logEvent(eventSource, "removePageNarrationAudio");
    setPageData({ ...pageData, narrativeAudio: null, audioUrl: null });
  };

  const showPagePreview = async (state = true) => {
    log.trace("showPagePreview", state);
    logEvent(eventSource, "showPagePreview");
    setOpenPreview(state);
  };

  const handleCharacterInputChange = (event, value) => {
    setAlertMessage({});
    setPageData({ ...pageData, characters: value });
  };

  const handleCharacterClick = (event, value) => {
    log.trace("handleCharacterClick", value);
  };

  const handleLocationInputChange = (event, value) => {
    setAlertMessage({});
    setPageData({ ...pageData, location: value });
  };

  // TODO any interesting actions here?
  const handleLocationClick = (event, value) => {
    log.trace("handleLocationClick", event, value);
  };

  const handleOpenTextGenerationDialog = (e, selectedOptionsData) => {
    log.trace("handleOpenTextGenerationDialog", e, selectedOptionsData);
    logEvent(eventSource, "handleOpenTextGenerationDialog");

    const optionsSource = selectedOptionsData
      ? selectedOptionsData
      : textGenerationOptions;
    setTextGenerationOptions({
      ...optionsSource,
      useCharacters:
        (pageData.characters && pageData.characters.length != 0) || "hidden",
      useLocation:
        (pageData.location && pageData.location.length != 0) || "hidden",
    });

    setOpenTextGenerationDialog(true);
  };

  const handleCloseTextGenerationDialog = selectedOptionsData => {
    log.trace("handleCloseTextGenerationDialog", selectedOptionsData);
    setTextGenerationOptions(selectedOptionsData);
    setOpenTextGenerationDialog(false);
  };

  const handleContinueTextGeneration = async selectedOptionsData => {
    log.trace("handleContinueTextGeneration", selectedOptionsData);
    setTextGenerationOptions(selectedOptionsData);
    setOpenTextGenerationDialog(false);

    suggestPageNarrativeFromIdeation(selectedOptionsData);
  };

  const handleOpenImageGenerationDialog = (e, selectedOptionsData) => {
    log.trace("handleOpenImageGenerationDialog", e, selectedOptionsData);
    logEvent(eventSource, "handleOpenImageGenerationDialog");

    // TODO how to properly handle no characters or location options
    const optionsSource = selectedOptionsData
      ? selectedOptionsData
      : imageGenerationOptions;

    setImageGenerationOptions({
      ...optionsSource,
      useCharacters:
        (pageData.characters && pageData.characters.length != 0) || "hidden",
      useLocation:
        (pageData.location && pageData.location.length != 0) || "hidden",
      useContext: selectedOptionsData ? selectedOptionsData.useContext : false,
      context: selectedOptionsData ? selectedOptionsData.context : null,
    });

    setOpenImageGenerationDialog(true);
  };

  const handleCloseImageGenerationDialog = selectedOptionsData => {
    log.trace("handleCloseImageGenerationDialog", selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);
    setOpenImageGenerationDialog(false);
  };

  const handleContinueImageGeneration = async selectedOptionsData => {
    log.trace("handleContinueImageGeneration", selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);
    setOpenImageGenerationDialog(false);

    suggestBackgroundImageFromPageNarrative(selectedOptionsData);
  };

  const handleOpenAudioGenerationDialog = () => {
    log.trace("handleOpenAudioGenerationDialog");
    logEvent(eventSource, "handleOpenAudioGenerationDialog");
    setOpenAudioGenerationDialog(true);
  };

  const handleCloseAudioGenerationDialog = selectedOptionsData => {
    log.trace("handleCloseAudioGenerationDialog", selectedOptionsData);
    setAudioGenerationOptions(selectedOptionsData);

    setOpenAudioGenerationDialog(false);
  };

  const handleContinueAudioGeneration = async selectedOptionsData => {
    log.trace("handleContinueAudioGeneration", selectedOptionsData);
    setAudioGenerationOptions(selectedOptionsData);
    setOpenAudioGenerationDialog(false);
    suggestPageNarrationAudio(selectedOptionsData);
  };

  const handleOpenImagePickerDialog = () => {
    log.trace("handleOpenImagePickerDialog");
    logEvent(eventSource, "handleOpenImagePickerDialog");
    setOpenImagePickerDialog(true);
  };

  const handleCloseImagePickerDialog = selectedOptionsData => {
    log.trace("handleCloseImagePickerDialog", selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);
    setOpenImagePickerDialog(false);
  };

  const handleContinueImagePicker = async selectedOptionsData => {
    log.trace("handleContinueImagePicker", selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);
    setOpenImagePickerDialog(false);

    //from computer or from gallery, ...
    if (!selectedOptionsData) {
      log.trace("handleContinueImagePicker.fromComputer");
      logEvent(eventSource, "handleContinueImagePicker.fromComputer");

      imagePickerSelectFromComputer();
    } else {
      log.trace("handleContinueImagePicker.fromGallery");
      logEvent(eventSource, "handleContinueImagePicker.fromGallery");
      const filePath = selectedOptionsData.filePath;
      const imagePrompt = selectedOptionsData.imagePrompt;
      const imageUrl = selectedOptionsData.imageUrl;

      //set form data with image previously generated. image is already stored, so just get the file path
      setPageData({
        ...pageData,
        imagePrompt: imagePrompt,
        backgroundImage: filePath,
        imageUrl: imageUrl,
        newBackgroundImage: null,
      });
    }
  };

  const imagePickerSelectFromComputer = () => {
    log.trace("imagePickerSelectFromComputer");

    uploadInputRef.current && uploadInputRef.current.click();
  };

  function handleContextMenu(e) {
    log.trace("handleContextMenu", e);

    show({
      event: e,
    });
  }

  const handleItemClick = action => {
    log.trace("handleItemClick", action);

    const selection = window.getSelection().toString();
    if (selection.length === 0) {
      return;
    }

    if (action === "suggestImage") {
      const options = {
        ...imageGenerationOptions,
        useContext: true,
        context: selection,
      };
      handleOpenImageGenerationDialog(null, options);
    } else if (action === "rephrase") {
      // TODO
    }
  };

  const handleCreateChoice = () => {
    log.trace("handleCreateChoice");
    logEvent(eventSource, "handleCreateChoice");

    handleOpenChoiceDialog();
  };

  const handleOpenChoiceDialog = choiceData => {
    log.trace("handleOpenChoiceDialog", choiceData);
    setChoice(choiceData);
    setOpenChoiceDialog(true);
  };

  const handleCloseChoiceDialog = choiceData => {
    log.trace("handleCloseChoiceDialog", choiceData);
    setChoice({});
    setOpenChoiceDialog(false);
  };

  const handleContinueChoiceDialog = choiceData => {
    log.trace("handleContinueChoiceDialog", choiceData);

    // add to memory model only
    var choices = pageData.choices ?? {};
    choiceData.order = choiceData.order || Object.keys(choices).length + 1; // inject order for new connections
    choices[choiceData.leadToPage] = choiceData;

    setPageData({ ...pageData, choices: choices });

    setChoice({});
    setOpenChoiceDialog(false);
  };

  const handleEditChoice = choiceData => {
    log.trace("handleEditChoice", choiceData);
    handleOpenChoiceDialog(choiceData);
  };

  const handleDeleteChoiceDialog = async choiceData => {
    log.trace("handleDeleteChoiceDialog", choiceData);

    // remove from memory model only
    var choices = pageData.choices ?? {};
    delete choices[choiceData.leadToPage];
    setChoice({});

    setPageData({ ...pageData, choices: choices });

    setOpenChoiceDialog(false);
  };

  const handleRemoveImage = () => {
    log.trace("handleRemoveImage");
    setPageData({
      ...pageData,
      backgroundImage: null,
      imageUrl: null,
      newBackgroundImage: null,
    });
  };

  const uploadInputRef = useRef(null);

  return (
    <PageContainer
      title=""
      breadcrumbs={[
        { title: t("text.overview"), path: `/console/books/${pageParams.id}` },
        {
          title: t("text.pages"),
          path: `/console/books/${pageParams.id}/pages`,
        },
        {
          title: t("text.page"),
          path: `/console/books/${pageParams.id}/pages/${pageParams.pid}`,
        },
      ]}
    >
      <Box component="section">
        {alertMessage.message && (
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        )}

        {loading ? (
          <LinearProgress />
        ) : (
          <Box component="section" sx={{ p: 2 }}>
            <TextGenerationDialog
              title={t("textGenerationDialog.title")}
              message={t("textGenerationDialog.message")}
              open={openTextGenerationDialog}
              onClose={handleCloseTextGenerationDialog}
              onContinue={handleContinueTextGeneration}
              optionsData={textGenerationOptions}
            />

            <ImageGenerationDialog
              title={t("imageGenerationDialog.title")}
              message={t("imageGenerationDialog.message")}
              open={openImageGenerationDialog}
              onClose={handleCloseImageGenerationDialog}
              onContinue={handleContinueImageGeneration}
              optionsData={imageGenerationOptions}
            />

            <AudioGenerationDialog
              title={t("audioGenerationDialog.title")}
              message={t("audioGenerationDialog.message")}
              open={openAudioGenerationDialog}
              onClose={handleCloseAudioGenerationDialog}
              onContinue={handleContinueAudioGeneration}
              optionsData={audioGenerationOptions}
            />

            <ImagePicker
              title={t("imagePicker.title")}
              message={t("imagePicker.message")}
              open={openImagePickerDialog}
              onClose={handleCloseImagePickerDialog}
              onContinue={handleContinueImagePicker}
              optionsData={imagePickerOptions}
            />

            <ChoiceDialog
              title={t("view.choiceDialog.text.title")}
              message={t("view.choiceDialog.text.message")}
              open={openChoiceDialog}
              onClose={handleCloseChoiceDialog}
              onContinue={handleContinueChoiceDialog}
              onDelete={handleDeleteChoiceDialog}
              choiceData={choice}
              pagesAlreadyLinked={
                pageData.choices
                  ? Object.keys(pageData.choices)
                      .concat([pageData.pageNumber])
                      .map(String)
                  : [pageData.pageNumber]
              }
            />

            <Menu id={CONTEXT_MENU_ID}>
              <Submenu label="Image">
                <Item onClick={e => handleItemClick("suggestImage")}>
                  {t("text.suggestWithText")}
                </Item>
              </Submenu>
              <Separator />
            </Menu>

            <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 1 }}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="plot-content"
                  id="plot-header"
                >
                  {t("header.plot")}
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2} direction="column">
                    {bookData.isNonSequentialBook && (
                      <TextField
                        id="pageNumber"
                        name="pageNumber"
                        fullWidth
                        value={pageData.pageNumber}
                        onChange={handleInputChange}
                        label={t("text.pageNumber")}
                        variant="outlined"
                        type="number"
                      />
                    )}
                    {charactersList.options?.length > 0 && (
                      <Autocomplete
                        {...charactersList}
                        id="characters-auto-complete"
                        name="characters-auto-complete"
                        fullWidth
                        autoComplete
                        multiple
                        filterSelectedOptions
                        onChange={handleCharacterInputChange}
                        value={pageData.characters}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { key, ...tagProps } = getTagProps({ index });
                            return (
                              <Chip
                                variant="filled"
                                label={option.name}
                                key={key}
                                onClick={e => handleCharacterClick(e, option)}
                                {...tagProps}
                              />
                            );
                          })
                        }
                        renderInput={character => (
                          <TextField
                            {...character}
                            key={character}
                            variant="standard"
                            label={t("text.charactersInScene")}
                          />
                        )}
                      />
                    )}
                    {locationsList.options?.length > 0 && (
                      <Autocomplete
                        {...locationsList}
                        id="location-auto-complete"
                        name="location-auto-complete"
                        fullWidth
                        autoComplete
                        filterSelectedOptions
                        onChange={handleLocationInputChange}
                        value={pageData.location}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { key, ...tagProps } = getTagProps({ index });
                            return (
                              <Chip
                                variant="filled"
                                label={option.name}
                                key={key}
                                onClick={e => handleLocationClick(e, option)}
                                {...tagProps}
                              />
                            );
                          })
                        }
                        renderInput={location => (
                          <TextField
                            {...location}
                            key={location}
                            variant="standard"
                            label={t("text.sceneLocation")}
                          />
                        )}
                      />
                    )}
                    <TextField
                      id="ideation"
                      name="ideation"
                      required
                      autoFocus
                      fullWidth
                      multiline
                      maxRows={20}
                      value={pageData.ideation}
                      onChange={handleInputChange}
                      label={t("text.ideation")}
                      variant="outlined"
                    />
                    <Button
                      variant="text"
                      onClick={handleOpenTextGenerationDialog}
                      startIcon={<ArticleIcon />}
                    >
                      {t("buttonAction.suggestNarrativeText")}
                    </Button>
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="plot-content"
                  id="plot-header"
                >
                  {t("header.narrative")}
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2} direction="column">
                    <TextField
                      id="narrative"
                      name="narrative"
                      multiline
                      fullWidth
                      maxRows={20}
                      value={pageData.narrative}
                      onChange={handleInputChange}
                      label={t("text.narrative")}
                      variant="outlined"
                      onContextMenu={handleContextMenu}
                    />
                    {pageData.audioUrl && (
                      <Typography
                        variant="caption"
                        sx={{ textAlign: "center" }}
                      >
                        {(pageData.audioVoiceType
                          ? pageData.audioVoiceType.label + " "
                          : "") + pageData.narrativeAudio}
                      </Typography>
                    )}
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                    >
                      <Button
                        variant="text"
                        onClick={handleOpenImageGenerationDialog}
                        startIcon={<AddPhotoAlternateIcon />}
                      >
                        {t("buttonAction.suggestBackgroundImage")}
                      </Button>
                      {pageData.audioUrl ? (
                        <>
                          <audio
                            id="narrativeAudio"
                            name="narrativeAudio"
                            controls
                            src={pageData.audioUrl}
                          >
                            {t("error.noAudioSupport")}
                          </audio>
                          <IconButton
                            aria-label="Delete audio"
                            color="error"
                            onClick={removePageNarrationAudio}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </>
                      ) : (
                        <Button
                          variant="text"
                          onClick={handleOpenAudioGenerationDialog}
                          startIcon={<VolumeUpIcon />}
                        >
                          {t("buttonAction.generateAudio")}
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </AccordionDetails>
              </Accordion>

              {bookData.isNonSequentialBook && (
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="plot-content"
                    id="plot-header"
                  >
                    {t("header.choices")}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack spacing={2} direction="column">
                      {pageData.choices &&
                        Object.values(pageData.choices)
                          .sort((a, b) => a.order - b.order)
                          .map(choice => {
                            return (
                              <Stack
                                direction="row"
                                spacing={2}
                                key={choice.leadToPage}
                              >
                                <TextField
                                  readonly
                                  fullWidth
                                  multiline
                                  margin="dense"
                                  id={choice.leadToPage}
                                  name={choice.leadToPage}
                                  label={t("text.choice")}
                                  type="text"
                                  value={choice.text}
                                  variant="standard"
                                />
                                <TextField
                                  readonly
                                  margin="dense"
                                  id="leadToPage"
                                  name="leadToPage"
                                  label={t("view.page.text.leadToPage")}
                                  type="text"
                                  value={choice.leadToPage}
                                  sx={{ width: "20%" }}
                                  variant="standard"
                                />
                                <Button
                                  onClick={() => handleEditChoice(choice)}
                                  variant="text"
                                  startIcon={<EditIcon />}
                                ></Button>
                              </Stack>
                            );
                          })}

                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                      >
                        <Button
                          onClick={handleCreateChoice}
                          variant="text"
                          sx={{ width: "100", height: "100%" }}
                        >
                          <Stack
                            direction="column"
                            spacing={2}
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <AddCircleOutlineOutlinedIcon />
                            {t(
                              "buttonAction.createNewChoice"
                            ).toLocaleUpperCase()}
                          </Stack>
                        </Button>
                      </Stack>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              )}

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="images-content"
                  id="images-header"
                >
                  {t("header.media")}
                </AccordionSummary>
                <AccordionDetails>
                  <Box component="section" sx={{ p: 2 }}>
                    <Stack direction="row" justifyContent="center" spacing={2}>
                      <Button
                        onClick={handleOpenImagePickerDialog}
                        variant="contained"
                      >
                        {t("buttonAction.selectBackgroundImage")}
                      </Button>
                      {pageData.backgroundImage && (
                        <IconButton
                          onClick={handleRemoveImage}
                          color="error"
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Stack>

                    {pageData.backgroundImage && (
                      <Box sx={{ pt: 2, pb: 2 }}>
                        <Avatar
                          alt={pageData.backgroundImage}
                          src={pageData.imageUrl}
                          variant="square"
                          sx={{ width: "100%", height: "30%" }}
                          onLoad={handlePageBackgroundImageLoaded}
                        />
                        <Typography
                          variant="caption"
                          id="backgroundImage"
                          name="backgroundImage"
                          sx={{ textAlign: "right" }}
                        >
                          {pageData.backgroundImage}
                        </Typography>
                      </Box>
                    )}
                    <input
                      id="newBackgroundImage"
                      name="newBackgroundImage"
                      ref={uploadInputRef}
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Stack
                spacing={2}
                direction="row"
                sx={{ p: 2 }}
                justifyContent="center"
              >
                <Button variant="contained" type="submit" color="primary">
                  {t("buttonAction.save")}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancel}
                >
                  {t("buttonAction.cancel")}
                </Button>
              </Stack>
            </Box>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

export default Page;
