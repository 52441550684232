import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "en-US", "pt", "pt-BR"], // list of supported languages
    fallbackLng: "en-US", // default language
    detection: {
      order: ["queryString", "cookie"],
      cache: ["cookie"],
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", // path to language files
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
