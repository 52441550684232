import { Box, Typography } from "@mui/material";
import React from "react";
import { logEvent } from "./Services/firebase";
import log from "./Tools/Log";

function Error() {
  return (
    <Box component="section">
      <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
        Oops! Please make sure you are authorized to see this content.
      </Typography>
    </Box>
  );
}

export default Error;
