import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { logEvent } from "../../Services/firebase";
import { requestHeaders } from "../../Tools/DataUtils";
import {
  handleActionException,
  handleDataFetchException,
} from "../../Tools/ErrorHandling";
import log from "../../Tools/Log";

function BookOverview() {
  const eventSource = "BookOverview";

  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [alertMessage, setAlertMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [bookData, setBookData] = useState({});

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    if (!pageParams.id) {
      log.trace("no id, returning to books");
      navigate("/console/books");
    }

    fetchData();
  }, []);

  const fetchData = async (isChained = false) => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      if (!isChained) showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/getBookOverview`,
            { bookUuid: pageParams.id },
            headers
          )
          .then(response => {
            log.trace("fetchData.response.data", response.data);
            logEvent(eventSource, "fetchData.response");

            setBookData(response.data);
          })
      );

      await Promise.all(requests).finally(() => {
        if (!isChained) hideLoadingUI();
      });
    } catch (exception) {
      const response = handleDataFetchException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      if (!isChained) hideLoadingUI();
    }
  };

  const handlePublish = async () => {
    log.trace("handlePublish.request");
    logEvent(eventSource, "handlePublish.request");

    if (bookData.numberOfPages == 0) {
      setAlertMessage({
        message: t("view.bookOverview.text.bookHasNoPages"),
        severity: "error",
      });
      return;
    }

    if (bookData.hasCoverImage == false) {
      setAlertMessage({
        message: t("view.bookOverview.text.bookHasNoCoverImage"),
        severity: "error",
      });
      return;
    }

    try {
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/publishBook`,
            { bookUuid: pageParams.id },
            headers
          )
          .then(response => {
            log.trace("handlePublish.response.data", response.data);
            logEvent(eventSource, "handlePublish.response");

            setAlertMessage({
              message: t("view.bookOverview.text.bookPublishedSuccessfully"),
              severity: "success",
            });
          })
      );

      await Promise.all(requests).finally(() => {
        fetchData(true);
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleActionException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  return (
    <PageContainer title={t("text.overview")} breadcrumbs="">
      <Box component="section">
        {alertMessage.message && (
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        )}
        {loading ? (
          <LinearProgress />
        ) : (
          <Box component="section">
            <Stack direction="row" spacing={2}>
              <Card
                variant="outlined"
                sx={{ textAlign: "center", width: "150px" }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    sx={{ color: "text.secondary", fontSize: 14 }}
                  >
                    {t("text.pages").toLocaleUpperCase()}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {bookData.numberOfPages}
                  </Typography>
                </CardContent>
              </Card>

              <Card
                variant="outlined"
                sx={{ textAlign: "center", width: "150px" }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    sx={{ color: "text.secondary", fontSize: 14 }}
                  >
                    {t("text.characters").toLocaleUpperCase()}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {bookData.numberOfCharacters}
                  </Typography>
                </CardContent>
              </Card>
              <Card
                variant="outlined"
                sx={{ textAlign: "center", width: "150px" }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    sx={{ color: "text.secondary", fontSize: 14 }}
                  >
                    {t("text.locations").toLocaleUpperCase()}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {bookData.numberOfLocations}
                  </Typography>
                </CardContent>
              </Card>
              <Card
                variant="outlined"
                sx={{ textAlign: "center", width: "150px" }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    sx={{ color: "text.secondary", fontSize: 14 }}
                  >
                    {t("text.timelineEvents").toLocaleUpperCase()}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {bookData.numberOfTimelineEvents}
                  </Typography>
                </CardContent>
              </Card>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ pt: 2 }}>
              <Card
                variant="outlined"
                sx={{ textAlign: "center", width: "150px" }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    sx={{ color: "text.secondary", fontSize: 14 }}
                  >
                    {t("text.version").toLocaleUpperCase()}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {Math.ceil(bookData.version || 0.1)}
                  </Typography>
                  <Typography variant="body" component="div">
                    {bookData.lastUpdateAt
                      ? new Date(
                          bookData.lastUpdateAt._seconds * 1000 +
                            bookData.lastUpdateAt._nanoseconds / 1000000
                        ).toLocaleDateString(i18n.language, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      : ""}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handlePublish}
                  >
                    {t("buttonAction.publish")}
                  </Button>
                </CardActions>
              </Card>
              <Card
                variant="outlined"
                sx={{ textAlign: "center", width: "150px" }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    sx={{ color: "text.secondary", fontSize: 14 }}
                  >
                    {t("text.liveVersion").toLocaleUpperCase()}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {bookData.publishedVersion ?? t("text.notPublished")}
                  </Typography>
                  <Typography variant="body" component="div">
                    {bookData.publishedAt
                      ? new Date(
                          bookData.publishedAt._seconds * 1000 +
                            bookData.publishedAt._nanoseconds / 1000000
                        ).toLocaleDateString(i18n.language, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      : ""}
                  </Typography>
                </CardContent>
              </Card>
            </Stack>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

export default BookOverview;
