import { Alert, Box, LinearProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import * as d3 from "d3";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { logEvent } from "../../Services/firebase";
import {
  copyDataToModel,
  formSubmitHeaders,
  requestHeaders,
} from "../../Tools/DataUtils";
import {
  handleActionException,
  handleDataFetchException,
} from "../../Tools/ErrorHandling";
import log from "../../Tools/Log";
import PageInfoDrawer from "../../UiComponents/PageInfoDrawer";
import Wizard from "../../UiComponents/Wizard";

// TODO need to work around d3 and react conflict on DOM manipulation in order for these to be removed
var selectedNodeForContextAction = null;
var contextActionTemporaryConnectionLine = null;
var contextActionNewConnectionLine = null;
var contextActionTemporaryChoice = null;

const Pathways = () => {
  const eventSource = "Pathways";
  const svgRef = useRef();
  const containerRef = useRef();
  const theme = useTheme();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [bookId] = useState(pageParams.id);
  const [pages, setPages] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isInspectorVisible, setIsInspectorVisible] = React.useState(false);
  const [selectedNode, setSelectedNode] = useState(null);

  // TODO need to work around d3 and react conflict on DOM manipulation in order for these to work
  // const [selectedNodeForContextAction, setSelectedNodeForContextAction] = useState(null);
  // const [contextActionTemporaryConnectionLine, setContextActionTemporaryConnectionLine] = useState(null);
  // const [contextActionNewConnectionLine, setContextActionNewConnectionLine] = useState(null);
  // const [contextActionTemporaryChoice, setContextActionTemporaryChoice] = useState(null);

  const wizardKey = "isWizardOpen_" + eventSource;
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [wasWizardPresentedOnce, setWasWizardPresentedOnce] = useState(false);
  useEffect(() => {
    log.trace("setItem isWizardOpen", wasWizardPresentedOnce);
    if (wasWizardPresentedOnce === true) {
      localStorage.setItem(wizardKey, JSON.stringify(wasWizardPresentedOnce));
    }
  }, [wasWizardPresentedOnce]);

  const showWizardIfNotSeen = () => {
    const savedWizardState = localStorage.getItem(wizardKey);
    log.trace("showWizardIfNotSeen", savedWizardState);
    if (savedWizardState === null) {
      setIsWizardOpen(true);
    }
  };

  const handleCloseWizard = () => {
    log.trace("handleCloseWizard");
    setIsWizardOpen(false);
    setWasWizardPresentedOnce(true);
  };

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  const clearContextData = () => {
    log.debug("clearContextData");
    contextActionTemporaryConnectionLine?.remove();
    contextActionNewConnectionLine?.remove();
    selectedNodeForContextAction = null;
    contextActionTemporaryConnectionLine = null;
    contextActionNewConnectionLine = null;
    contextActionTemporaryChoice = null;
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    fetchData();

    showWizardIfNotSeen();

    const onBeforeUnload = e => {
      clearContextData();
    };
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      // TODO replace with implementation later
      const filters = {
        title: "search*",
      };

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/getPages`,
            {
              bookUuid: pageParams.id,
              filters,
            },
            headers
          )

          .then(pagesResponse => {
            log.trace("fetchData.response.data", pagesResponse.data);
            logEvent(eventSource, "fetchData.response");

            pagesResponse.data.map(item => {
              item.backgroundImage &&
                (item.imageUrl =
                  `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                  item.backgroundImage);

              item.narrativeAudio &&
                (item.audioUrl =
                  `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                  item.narrativeAudio);
            });

            setPages(pagesResponse.data);
          })
      );

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/getLocations`,
            {
              bookUuid: pageParams.id,
              filters,
            },
            headers
          )
          .then(locationsResponse => {
            log.trace("fetchData.response.data", locationsResponse.data);
            logEvent(eventSource, "fetchData.response");

            locationsResponse.data.map(item => {
              item.imageUrl =
                `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                item.backgroundImage;
            });

            if (locationsResponse.data && locationsResponse.data.length > 0) {
              setLocations(locationsResponse.data);
            }
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleDataFetchException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  useEffect(() => {
    log.trace("useEffect");

    buildGraph();
  }, [theme, pages]);

  const hideInspector = () => {
    log.trace("hideInspector", selectedNodeForContextAction);

    setIsInspectorVisible(false);
    setSelectedNode(null);
    d3.select(containerRef.current).style("pointer-events", "auto");

    selectedNodeForContextAction &&
      contextActionTemporaryChoice &&
      selectedNodeForContextAction.page.choices &&
      delete selectedNodeForContextAction.page.choices[
        contextActionTemporaryChoice.leadToPage
      ];

    contextActionTemporaryChoice = null;
    selectedNodeForContextAction = null;
    contextActionTemporaryConnectionLine?.remove();
    contextActionTemporaryConnectionLine = null;
    contextActionNewConnectionLine?.remove();
    contextActionNewConnectionLine = null;
    setAlertMessage({});
  };

  const handleInspectorUpdate = async page => {
    log.trace("handleInspectorUpdate", page);
    hideInspector();
    await handleFormSubmit(page);
  };

  const handleFormSubmit = async page => {
    log.trace("handleFormSubmit.request", page);
    logEvent(eventSource, "handleFormSubmit.request");

    try {
      showLoadingUI();

      const requests = [];
      const headers = await formSubmitHeaders();

      const formData = new FormData();

      formData.append("bookUuid", bookId);
      copyDataToModel(page, formData);

      requests.push(
        axios
          .put(`${process.env.REACT_APP_API_URL}/putPage`, formData, headers)
          .then(response => {
            log.trace("handleFormSubmit.response.data", response.data);

            if (response.status === 200) {
              setAlertMessage({
                message: t("message.saved"), // TODO message.saved does not exist in translation file
                severity: "success",
              });

              fetchData();
            } else {
              setAlertMessage({
                message: t("error.action"),
                severity: "error",
              });
            }
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleActionException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  const buildGraph = () => {
    log.trace("buildGraph", pages.length);
    logEvent(eventSource, "buildGraph");

    if (pages.length === 0) {
      return;
    }

    const isDarkTheme = theme.palette.mode === "dark";
    // Set dimensions for the SVG
    //TODO calculate based on number of pages
    var width = 1500;
    var height = 1500;

    const nodeSize = 100;
    const nodeBGRadius = 55;
    const nodeFullSize = nodeBGRadius * 2;
    const nodeDistance = 200;

    let pageNodes = [];
    let pageLinks = [];
    let rootNode = null;

    pages.map(page => {
      const newNode = {
        id: page.pageNumber,
        isLeaf: true,
        page: page,
      };

      if (rootNode == null) {
        rootNode = newNode;
      }

      if (page.choices) {
        newNode.isLeaf = false;
        for (const [key, choice] of Object.entries(page.choices)) {
          var link = {
            source: page.pageNumber,
            target: choice.leadToPage,
          };
          pageLinks.push(link);
        }
      }

      pageNodes.push(newNode);
    });
    rootNode.isRoot = true;
    rootNode.isLeaf = false;

    var nodesNotFound = [];

    // remove links to non-existing pages
    // fixme should show node with different markup
    pageLinks = pageLinks.filter(link => {
      const node = pageNodes.find(node => node.id === link.target);
      if (node == null) {
        nodesNotFound.push(link.target);
      }
      return node != null;
    });

    if (nodesNotFound.length > 0) {
      log.warn("Nodes not found", nodesNotFound);
      nodesNotFound = [...new Set(nodesNotFound)];
      nodesNotFound.sort((a, b) => a - b);
      setAlertMessage({
        message: t("view.pathways.text.nodesNotFound", {
          nodesNotFound: nodesNotFound.join(", "),
        }),
        severity: "error",
      });
    }

    width = Math.max(width, pageNodes.length * nodeSize);
    height = Math.max(height, pageNodes.length * nodeSize);

    const data = { nodes: pageNodes, links: pageLinks };

    log.trace("data", data);

    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", [0, 0, width, height]);
    //.attr("style", "max-width: 100%; height: 80vh; user-select: none;");

    svg.selectAll("*").remove();

    let x = d3
      .scaleLinear()
      .domain([-1, width + 1])
      .range([-1, width + 1]);
    let y = d3
      .scaleLinear()
      .domain([-1, height + 1])
      .range([-1, height + 1]);
    let xAxis = d3
      .axisBottom(x)
      .ticks(((width + 2) / (height + 2)) * 10)
      .tickSize(height)
      .tickPadding(8 - height);
    let yAxis = d3
      .axisRight(y)
      .ticks(10)
      .tickSize(width)
      .tickPadding(8 - width);
    const view = svg
      .append("rect")
      .attr(
        "style",
        "fill: rgba(255, 255, 255, 0.06); stroke:rgba(255, 242, 0, 0.48);"
      )
      //.attr("class", "view")
      .attr("x", 0.5)
      .attr("y", 0.5)
      .attr("width", width - 1)
      .attr("height", height - 1);

    const gX = svg.append("g").attr("class", "axis axis--x").call(xAxis);
    const gY = svg.append("g").attr("class", "axis axis--y").call(yAxis);

    const links = svg
      .append("g")
      .attr("stroke", theme.palette.text.primary)
      .attr("stroke-opacity", 0.6)
      .selectAll("line")
      .data(pageLinks)
      .join("line")
      .attr("stroke-width", d => Math.sqrt(d.value || 1));

    const bgCircles = svg
      .append("g")
      .attr("stroke", theme.palette.background.paper)
      .attr("stroke-width", 1.5)
      .selectAll("circle")
      .data(pageNodes)
      .join("circle")
      .attr("r", nodeBGRadius)
      .attr("fill", d =>
        d.isRoot
          ? theme.palette.success.main
          : d.isLeaf
            ? "rgba(255, 0, 0, 0.18)"
            : theme.palette.secondary.surface
      );

    const images = svg
      .append("g")
      .selectAll("image")
      .data(pageNodes)
      .join("image")
      .attr("xlink:href", d => d.page.imageUrl)
      .attr("width", nodeSize)
      .attr("height", nodeSize)
      .attr(
        "clip-path",
        `border-box circle(${nodeSize / 2}px at ${nodeSize / 2}px ${nodeSize / 2}px)`
      )
      .attr("x", d => d.x - nodeSize / 2)
      .attr("y", d => d.y - nodeSize / 2)
      .on("click", (e, d) => toggleNodeSelection(e, d))
      .on("contextmenu", (e, d) => selectNodeforConnections(e, d))
      .call(
        d3
          .drag()
          .on("start", (event, d) => {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
          })
          .on("drag", (event, d) => {
            // const transform = d3.zoomTransform(svg.node()); // Get current zoom transform
            // d.fx = transform.invertX(event.x);
            // d.fy = transform.invertY(event.y);
            d.fx = event.x;
            d.fy = event.y;
          })
          .on("end", (event, d) => {
            if (!event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
          })
      );

    images
      .on("mouseover", (e, d) => {
        d.page.location &&
          tooltip.style("visibility", "visible").text(d.page.location);

        d3.selectAll("circle")
          .filter(circleNode => circleNode.id === d.id)
          .attr("stroke", theme.palette.primary.main)
          .attr("stroke-width", 3);

        contextActionTemporaryConnectionLine &&
          contextActionTemporaryConnectionLine
            .attr("x2", d.x)
            .attr("y2", d.y)
            .attr("transform", links.attr("transform"));
      })
      .on("mousemove", (e, d) => {
        tooltip
          .style("top", `${e.pageY - 10}px`)
          .style("left", `${e.pageX + 10}px`);

        contextActionTemporaryConnectionLine &&
          contextActionTemporaryConnectionLine
            .attr("x2", d.x)
            .attr("y2", d.y)
            .attr("transform", links.attr("transform"));
      })
      .on("mouseout", (e, d) => {
        tooltip.style("visibility", "hidden");

        d3.selectAll("circle")
          .filter(circleNode => circleNode.id === d.id)
          .attr("stroke", null)
          .attr("stroke-width", null);

        contextActionTemporaryConnectionLine &&
          contextActionTemporaryConnectionLine
            .attr("x2", d.x)
            .attr("y2", d.y)
            .attr("transform", links.attr("transform"));
      });

    const labels = svg
      .append("g")
      .selectAll("text")
      .data(pageNodes)
      .join("text")
      .attr("dy", "2.8em")
      .attr("x", d => d.x)
      .attr("y", d => d.y)
      .attr("text-anchor", "middle")
      .text(d => d.page.pageNumber)
      .attr("font-size", 16)
      .attr("font-weight", "bold")
      .attr("fill", "white");

    const tooltip = d3
      .select("body")
      .append("div")
      .style("position", "absolute")
      .style("visibility", "hidden")
      .style("color", theme.palette.primary.text)
      .style(
        "background-color",
        isDarkTheme ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)"
      )
      .style("border", `1px solid ${theme.palette.divider}`)
      .style("padding", "5px")
      .style("border-radius", "5px")
      .style("box-shadow", "0px 0px 5px rgba(0,0,0,0.5)");

    // Force simulation
    const simulation = d3
      .forceSimulation(pageNodes)
      .velocityDecay(0.2)
      .force("x", d3.forceX().strength(0.002))
      .force("y", d3.forceY().strength(0.002))
      .force("collide", d3.forceCollide().radius(nodeBGRadius).iterations(2))
      .force(
        "link",
        d3
          .forceLink(pageLinks)
          .id(d => d.id)
          .distance(nodeDistance)
      )
      .force("charge", d3.forceManyBody().strength(-nodeDistance))
      .force("center", d3.forceCenter(width / 2, height / 2));

    // Update the simulation
    simulation.on("tick", () => {
      links
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);

      bgCircles.attr("cx", d => d.x).attr("cy", d => d.y);

      labels.attr("x", d => d.x).attr("y", d => d.y);

      images
        .attr("x", d => d.x - nodeSize / 2)
        .attr("y", d => d.y - nodeSize / 2);
    });

    const zoom = d3
      .zoom()
      .scaleExtent([0.5, 15.0])
      .translateExtent([
        [-100, -100],
        [width + 90, height + 100],
      ])
      .filter(filter)
      .on("zoom", zoomed);

    return Object.assign(svg.call(zoom).node(), { reset });

    function zoomed({ transform }) {
      //view.attr("transform", transform);
      links.attr("transform", transform);
      labels.attr("transform", transform);
      bgCircles.attr("transform", transform);
      images.attr("transform", transform);

      gX.call(xAxis.scale(transform.rescaleX(x)));
      gY.call(yAxis.scale(transform.rescaleY(y)));
    }

    function reset() {
      svg.transition().duration(750).call(zoom.transform, d3.zoomIdentity);
    }

    // prevent scrolling then apply the default filter
    function filter(event) {
      event.preventDefault();
      return (!event.ctrlKey || event.type === "wheel") && !event.button;
    }

    function toggleNodeSelection(e, node) {
      log.trace("toggleNodeSelection", node);

      setSelectedNode(node);
      setIsInspectorVisible(true);
    }

    function selectNodeforConnections(e, node) {
      log.trace("selectNodeforConnections", node, selectedNodeForContextAction);
      e.preventDefault();

      var page = node.page;
      var contextPage = selectedNodeForContextAction?.page;

      if (contextPage != null) {
        // ignore if selected node is the same as context node
        if (page.pageNumber == contextPage.pageNumber) {
          log.trace("same page");
          clearContextData();
          return;
        }

        // if node.page.choices already contains a choice with the same target page, ignore it
        if (page.choices && page.choices[contextPage.pageNumber] != null) {
          log.trace(
            "no 2-way connections; destination page already links to this page"
          );
          clearContextData();
          return;
        }

        contextPage.choices = contextPage.choices || {};
        var existingConnection = contextPage.choices[page.pageNumber];

        // if connection already exists between the nodes, ignore it
        if (existingConnection != null) {
          log.trace("destination page already connected");
          clearContextData();
          return;
        }

        var choice = {
          text: t("view.pathways.text.newConnectionText"),
          leadToPage: page.pageNumber,
          order: Object.values(contextPage.choices).length + 1,
        };
        contextPage.choices[choice.leadToPage] = choice;
        contextActionTemporaryChoice = choice;

        // Draw a line from the selected node to the context action node
        contextActionTemporaryConnectionLine?.remove();
        contextActionNewConnectionLine?.remove();
        contextActionNewConnectionLine = svg
          .append("line")
          .attr("x1", node.x)
          .attr("y1", node.y)
          .attr("x2", selectedNodeForContextAction.x)
          .attr("y2", selectedNodeForContextAction.y)
          .attr("stroke", theme.palette.success.main)
          .attr("stroke-width", 4)
          .attr("transform", links.attr("transform"));

        d3.select(containerRef.current).style("pointer-events", "none");

        setAlertMessage({
          message: t("view.pathways.text.unsavedChanges"),
          severity: "warning",
        });

        log.trace("added page choice", choice);
      } else {
        log.trace("set page for context action", node);

        // set page for context action
        selectedNodeForContextAction = node;
        setSelectedNode(selectedNodeForContextAction);
        setIsInspectorVisible(true);

        // Draw a line from the selected node to the context action node
        contextActionTemporaryConnectionLine?.remove();
        contextActionTemporaryConnectionLine = svg
          .append("line")
          .attr("x1", node.x)
          .attr("y1", node.y)
          .attr("x2", selectedNodeForContextAction.x)
          .attr("y2", selectedNodeForContextAction.y)
          .attr("stroke", theme.palette.error.main)
          .attr("stroke-width", 4)
          .attr("transform", links.attr("transform"));
      }
    }
  };

  const Step1 = () => {
    return (
      <Box component="section" sx={{ p: 2 }}>
        <Typography variant="body1" sx={{ pt: 2 }}>
          Each circle represents a page in your book. Click on a circle to see
          the overview details of a page in the right inspector.
        </Typography>
        <Typography variant="body1" sx={{ pt: 2 }}>
          This page makes it easier to see pages conections, add or remove new
          ones. The initial page has a green border, while terminal pages
          (without connections) have a red border.
        </Typography>
      </Box>
    );
  };

  const Step2 = () => {
    return (
      <Box component="section" sx={{ p: 2 }}>
        <Typography variant="body1">
          To connect two pages, first right click on the parent page. Moving the
          mouse around will illustrate where the new connection will be made.
        </Typography>
        <Typography variant="body1" sx={{ pt: 2 }}>
          Now right click on the destination page to create the connection. The
          page will freeze so you can enter the details of the new choice. Once
          done, save or cancel the change to continue.
        </Typography>
      </Box>
    );
  };

  return (
    <PageContainer
      title=""
      breadcrumbs={[
        { title: t("text.overview"), path: `/console/books/${pageParams.id}` },
        {
          title: t("text.pathways"),
          path: `/console/books/${pageParams.id}/pathways`,
        },
      ]}
    >
      <Box component="section">
        {alertMessage.message && (
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        )}

        <Wizard
          open={isWizardOpen}
          title="Quick Guide"
          onClose={handleCloseWizard}
          totalSteps={2}
          steps={["Page Information", "Connecting pages"]}
          stepsComponents={[<Step1 />, <Step2 />]}
        />

        <PageInfoDrawer
          open={isInspectorVisible}
          onClose={hideInspector}
          onUpdate={handleInspectorUpdate}
          pageData={
            selectedNode ? JSON.parse(JSON.stringify(selectedNode.page)) : null
          }
        />

        {loading ? (
          <LinearProgress />
        ) : (
          <Box ref={containerRef} component="section">
            {pages.length == 0 ? (
              <Typography variant="body1">
                As you add pages you will see the pathways here.
              </Typography>
            ) : (
              <svg
                ref={svgRef}
                viewBox="0, 0, 1500, 1500"
                style={{
                  background: theme.palette.background.paper,
                  maxWidth: "100%",
                  height: "80vh",
                  userSelect: "none",
                }}
              ></svg>
            )}
          </Box>
        )}
      </Box>
    </PageContainer>
  );
};

export default Pathways;
