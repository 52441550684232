import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BadgeIcon from "@mui/icons-material/Badge";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ForumIcon from "@mui/icons-material/Forum";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SchoolIcon from "@mui/icons-material/School";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function ConsoleLayout() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [pathname, setPathname] = useState(location.pathname);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const NAVIGATION = [
    {
      kind: "header",
      title: t("text.home"),
    },
    {
      segment: "console",
      title: t("text.dashboard"),
      icon: <DashboardIcon />,
    },
    {
      segment: "console/books",
      title: t("text.books"),
      icon: <MenuBookIcon />,
    },
    {
      kind: "divider",
    },
    {
      kind: "header",
      title: t("text.analytics"),
    },
    {
      segment: "console/reports/feedback",
      title: t("text.userFeedback"),
      icon: <ForumIcon />,
    },
    {
      segment: "console/reports/traffic",
      title: t("text.traffic"),
      icon: <SsidChartIcon />,
    },
    {
      kind: "divider",
    },
    {
      kind: "header",
      title: t("text.account"),
    },
    {
      segment: "console/membership",
      title: t("text.membership"),
      icon: <BadgeIcon />,
    },
    {
      segment: "console/profile",
      title: t("text.profile"),
      icon: <AccountCircleIcon />,
    },
    {
      kind: "divider",
    },
    {
      kind: "header",
      title: t("text.help"),
    },
    {
      segment: "console/learningcenter",
      title: t("text.learningCenter"),
      icon: <SchoolIcon />,
    },
  ];

  const router = useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: path => {
        setPathname(String(path));
        navigate(path);
      },
    };
  }, [navigate, pathname]);

  const BRANDING = { logo: "", title: "LIBELL.US" };

  return (
    <AppProvider navigation={NAVIGATION} router={router} branding={BRANDING}>
      <DashboardLayout>
        <Outlet pathname={router.pathname} navigate={router.navigate} />
      </DashboardLayout>
    </AppProvider>
  );
}

export default ConsoleLayout;
