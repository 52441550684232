import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { logEvent } from "../../Services/firebase";
import { requestHeaders } from "../../Tools/DataUtils";
import { handleDataFetchException } from "../../Tools/ErrorHandling";
import log from "../../Tools/Log";

function Traffic() {
  const eventSource = "Traffic";

  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const [alertMessage, setAlertMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const [trafficData, setTrafficData] = useState([]);
  const [totalAggregator, setTotalAggregator] = useState({});

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    fetchData();
  }, []);

  useEffect(() => {
    log.trace("useEffect.trafficData");

    if (trafficData.length > 0) {
      setTotalAggregator(
        trafficData.reduce((acc, item) => {
          return {
            catalogViews: acc.catalogViews + item.catalogViews ?? 0,
            addToLibrary: acc.addToLibrary + item.addToLibrary ?? 0,
            removeFromLibrary:
              acc.removeFromLibrary + item.removeFromLibrary ?? 0,
            updateInLibrary: acc.updateInLibrary + item.updateInLibrary ?? 0,
            views: acc.views + item.views ?? 0,
            shares: acc.shares + item.shares ?? 0,
          };
        })
      );
    }
  }, [trafficData]);

  const fetchData = async (isChained = false) => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      if (!isChained) showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      requests.push(
        axios
          .post(`${process.env.REACT_APP_API_URL}/getTraffic`, {}, headers)
          .then(response => {
            log.trace("fetchData.response.data", response.data);
            logEvent(eventSource, "fetchData.response");

            setTrafficData(response.data);
          })
      );

      await Promise.all(requests).finally(() => {
        if (!isChained) hideLoadingUI();
      });
    } catch (exception) {
      const response = handleDataFetchException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      if (!isChained) hideLoadingUI();
    }
  };

  const maxTitleSizeInChars = 15;
  function addLabels(series) {
    return series.map(item => ({
      ...item,
      label: translations[item.dataKey],
      valueFormatter: v => (v ? `${v.toLocaleString()}` : "-"),
    }));
  }

  const translations = {
    catalogView: t("text.catalogViews").toLocaleUpperCase(),
    addToLibrary: t(
      "view.traffic.text.addedToLibraryViews"
    ).toLocaleUpperCase(),
    removeFromLibrary: t(
      "view.traffic.text.removedFromLibraryViews"
    ).toLocaleUpperCase(),
    updateInLibrary: t(
      "view.traffic.text.updatedInLibraryViews"
    ).toLocaleUpperCase(),
    views: t("text.views").toLocaleUpperCase(),
    shares: t("text.shares").toLocaleUpperCase(),
  };

  return (
    <PageContainer title={t("text.traffic")} breadcrumbs="">
      <Box component="section">
        {alertMessage.message && (
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        )}
        {loading ? (
          <LinearProgress />
        ) : (
          <Box component="section">
            {trafficData.length === 0 && (
              <Typography variant="body1">
                {t("view.traffic.text.noDataToPresent")}
              </Typography>
            )}

            {trafficData.length > 0 && totalAggregator && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="traffic-details-content"
                  id="traffic-details-header"
                >
                  {t("view.traffic.text.overallTop5").toLocaleUpperCase()}
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column" spacing={2}>
                    <Typography variant="body1">
                      {t("text.views").toLocaleUpperCase()}
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <PieChart
                        width={500}
                        height={500}
                        series={[
                          {
                            data: trafficData
                              .slice(0, 5)
                              .map((bookTrafficData, index) => ({
                                id: index,
                                value:
                                  (bookTrafficData.catalogViews /
                                    totalAggregator.catalogViews) *
                                  100,
                                label: bookTrafficData.bookTitle,
                              })),
                            innerRadius: 30,
                            outerRadius: 100,
                            paddingAngle: 5,
                            cornerRadius: 5,
                            startAngle: -45,
                            endAngle: 225,
                            cx: 150,
                            cy: 150,
                          },
                        ]}
                      />
                      <PieChart
                        width={500}
                        height={500}
                        series={[
                          {
                            data: trafficData
                              .slice(0, 5)
                              .map((bookTrafficData, index) => ({
                                id: index,
                                value:
                                  (bookTrafficData.views /
                                    totalAggregator.views) *
                                  100,
                                label: bookTrafficData.bookTitle,
                              })),
                            innerRadius: 30,
                            outerRadius: 100,
                            paddingAngle: 5,
                            cornerRadius: 5,
                            startAngle: -45,
                            endAngle: 225,
                            cx: 150,
                            cy: 150,
                          },
                        ]}
                      />
                    </Stack>
                  </Stack>
                  <Stack direction="column" spacing={2}>
                    <Typography variant="body1">
                      {t(
                        "view.traffic.text.catalogActions"
                      ).toLocaleUpperCase()}
                    </Typography>
                    <BarChart
                      dataset={trafficData.slice(0, 5)}
                      series={addLabels([
                        { dataKey: "addToLibrary", stack: "catalog" },
                        { dataKey: "removeFromLibrary", stack: "catalog" },
                        { dataKey: "updateInLibrary", stack: "update" },
                        { dataKey: "shares", stack: "shares" },
                      ])}
                      xAxis={[
                        {
                          scaleType: "band",
                          dataKey: "bookTitle",
                          valueFormatter: label => {
                            return label.length > maxTitleSizeInChars
                              ? `${label.slice(0, maxTitleSizeInChars)}...`
                              : label;
                          },
                        },
                      ]}
                      slotProps={{ legend: { hidden: false } }}
                      width={1000}
                      height={350}
                    />
                  </Stack>
                </AccordionDetails>
              </Accordion>
            )}

            {trafficData.map((bookTrafficData, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="traffic-details-content"
                  id="traffic-details-header"
                >
                  {bookTrafficData.metadata.title}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid size={4}>
                      <Typography variant="body1">
                        {t("text.catalogViews").toLocaleUpperCase()}
                      </Typography>
                      <Typography variant="body2">
                        {bookTrafficData.catalogViews ?? 0}
                      </Typography>
                    </Grid>
                    <Grid size={4}>
                      <Typography variant="body1">
                        {t("text.views").toLocaleUpperCase()}
                      </Typography>
                      <Typography variant="body2">
                        {bookTrafficData.views ?? 0}
                      </Typography>
                    </Grid>
                    <Grid size={4}>
                      <Typography variant="body1">
                        {t("text.shares").toLocaleUpperCase()}
                      </Typography>
                      <Typography variant="body2">
                        {bookTrafficData.shares ?? 0}
                      </Typography>
                    </Grid>
                    <Grid size={4}>
                      <Typography variant="body1">
                        {t(
                          "view.traffic.text.addedToLibraryViews"
                        ).toLocaleUpperCase()}
                      </Typography>
                      <Typography variant="body2">
                        {bookTrafficData.addToLibrary ?? 0}
                      </Typography>
                    </Grid>
                    <Grid size={4}>
                      <Typography variant="body1">
                        {t(
                          "view.traffic.text.removedFromLibraryViews"
                        ).toLocaleUpperCase()}
                      </Typography>
                      <Typography variant="body2">
                        {bookTrafficData.removeFromLibrary ?? 0}
                      </Typography>
                    </Grid>
                    <Grid size={4}>
                      <Typography variant="body1">
                        {t(
                          "view.traffic.text.updatedInLibraryViews"
                        ).toLocaleUpperCase()}
                      </Typography>
                      <Typography variant="body2">
                        {bookTrafficData.updateInLibrary ?? 0}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

export default Traffic;
