import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AutoFixHighIcon from "@mui/icons-material/Edit";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  Timeline as TimelineMUI,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
  TimelineSeparator,
} from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { logEvent } from "../../Services/firebase";
import {
  copyDataToModel,
  formSubmitHeaders,
  requestHeaders,
} from "../../Tools/DataUtils";
import {
  handleActionException,
  handleDataFetchException,
} from "../../Tools/ErrorHandling";
import log from "../../Tools/Log";
import ConfirmationDialog from "../../UiComponents/ConfirmationDialog";
import TimelineDialog from "../../UiComponents/TimelineDialog";
import "./Timeline.css";

function Timeline() {
  const eventSource = "Timeline";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [timeline, setTimeline] = useState({
    title: "",
    shortDescription: "",
    details: "",
    order: 0,
  });
  const [bookData, setBookData] = useState({});
  const [timelines, setTimelines] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteDialogConfirmationData, setDeleteDialogConfirmationData] =
    useState({});
  const [openTimelineDialog, setOpenTimelineDialog] = useState(false);
  const [openSuggestTimelineDialog, setOpenSuggestTimelineDialog] =
    useState(false);
  const [bookId] = useState(pageParams.id);

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    fetchData();
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/getBooks`,
            { filters: { bookUuid: pageParams.id } },
            headers
          )
          .then(bookResponse => {
            log.trace("fetchData.bookResponse.data", bookResponse.data);
            logEvent(eventSource, "fetchData.response");

            bookResponse.data.map(item => {
              item.imageUrl =
                `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                item.coverImage;
            });

            setBookData(bookResponse.data[0]);
          })
      );

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/getTimeline`,
            {
              bookUuid: pageParams.id,
              filters: { locationUuid: pageParams.lid },
            },
            headers
          )
          .then(timelineResponse => {
            log.trace("fetchData.timelineResponse.data", timelineResponse.data);
            logEvent(eventSource, "fetchData.response");

            if (timelineResponse.data && timelineResponse.data.length > 0) {
              setTimelines(timelineResponse.data);
            }
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleDataFetchException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  const handleCreateClick = () => {
    log.trace("handleCreateClick");
    handleOpenTimelineDialog();
  };

  const handleEventClick = timelineData => {
    log.trace("handleEventClick", timelineData);
    setTimeline(timelineData);
    handleOpenTimelineDialog(timelineData);
  };

  const handleOpenTimelineDialog = timelineData => {
    log.trace("handleOpenTimelineDialog", timelineData);
    setTimeline(timelineData);
    setOpenTimelineDialog(true);
  };

  const handleCloseTimelineDialog = timelineData => {
    log.trace("handleCloseTimelineDialog", timelineData);
    setTimeline({});
    setOpenTimelineDialog(false);
  };

  const handleContinueTimelineDialog = async timelineData => {
    log.trace("handleContinueTimelineDialog", timelineData);
    handleFormSubmit(timelineData);
    setOpenTimelineDialog(false);
  };

  const handleFormSubmit = async timelineData => {
    log.trace("handleFormSubmit.request", timelineData);
    logEvent(eventSource, "handleFormSubmit.request");

    try {
      // e.preventDefault();
      showLoadingUI();

      const requests = [];
      const headers = await formSubmitHeaders();

      const creatingNew = !timelineData.uuid;

      const formData = new FormData();
      formData.append("bookUuid", pageParams.id);
      const excludedFields = creatingNew ? [] : ["order"];
      copyDataToModel(timelineData, formData, excludedFields);

      if (creatingNew) {
        requests.push(
          axios.post(
            `${process.env.REACT_APP_API_URL}/putTimeline`,
            formData,
            headers
          )
        );
      } else {
        requests.push(
          axios.put(
            `${process.env.REACT_APP_API_URL}/putTimeline`,
            formData,
            headers
          )
        );
      }

      await Promise.all(requests)
        .then(response => {
          log.trace("handleFormSubmit.response.data", response.data);
          logEvent(eventSource, "handleFormSubmit.response");
          fetchData();
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      const response = handleActionException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  const handleOpenDeleteDialog = () => {
    log.trace("handleOpenDeleteDialog");
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    log.trace("handleCloseDeleteDialog");
    setOpenDeleteDialog(false);
    setDeleteDialogConfirmationData({});
  };

  const handleDeleteTimelineDialog = async timelineData => {
    log.trace("handleDeleteTimelineDialog", timelineData);
    handleCloseTimelineDialog();
    handleDeleteClick(null, timelineData.uuid, timelineData);
  };

  const handleDeleteClick = async (event, timelineId, timeline) => {
    log.trace("handleDeleteClick" + timeline);

    setDeleteDialogConfirmationData({
      uuid: timelineId,
      title: timeline.title,
    });

    handleOpenDeleteDialog();
  };

  const handleConfirmDeleteDialog = async () => {
    log.trace("handleDeleteClick.request");
    logEvent(eventSource, "handleDeleteClick.request");

    handleCloseDeleteDialog();

    try {
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/deleteTimeline`,
            {
              bookUuid: bookId,
              timelineUuid: deleteDialogConfirmationData.uuid,
            },
            headers
          )
          .then(response => {
            log.trace("handleDeleteClick.response.data", response.data);
            logEvent(eventSource, "handleDeleteClick.response");
          })
      );

      await Promise.all(requests).finally(() => {
        setDeleteDialogConfirmationData({});
        hideLoadingUI();
        fetchData();
      });
    } catch (exception) {
      const response = handleActionException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  const handleMoveUpClick = async (event, timelineUuid, i) => {
    log.trace("handleMoveUpClick", event, timelineUuid, i);

    if (timelines.length < 2) {
      setAlertMessage({
        message: "Timeline has only one event.",
        severity: "warning", //success info warning error
      });
    } else if (timelines[0].uuid == timelineUuid) {
      // already first page
      setAlertMessage({
        message: "This is already the first event.",
        severity: "warning", //success info warning error
      });
    } else {
      //setAlertMessage({});
      swapTimelineEvents(timelines[i - 1].uuid, timelineUuid);
    }
  };

  const handleMoveDownClick = async (event, timelineUuid, i) => {
    log.trace("handleMoveDownClick", event, timelineUuid, i);

    if (timelines.length < 2) {
      setAlertMessage({
        message: "Timeline has only one event.",
        severity: "warning", //success info warning error
      });
    } else if (timelines.at(-1).uuid == timelineUuid) {
      // already last page
      setAlertMessage({
        message: "This is already the last event.",
        severity: "warning", //success info warning error
      });
    } else {
      // TODO
      //setAlertMessage({});
      swapTimelineEvents(timelineUuid, timelines[i + 1].uuid);
    }
  };

  const swapTimelineEvents = async (leftTimelineUuid, rightTimelineUuid) => {
    log.trace(
      "swapTimelineEvents.request",
      leftTimelineUuid,
      rightTimelineUuid
    );
    logEvent(eventSource, "swapTimelineEvents.request");

    try {
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/swapTimeline`,
            {
              bookUuid: bookId,
              leftTimelineUuid: leftTimelineUuid,
              rightTimelineUuid: rightTimelineUuid,
            },
            headers
          )
          .then(response => {
            log.trace("swapTimelineEvents.response.data", response.data);
            logEvent(eventSource, "swapTimelineEvents.response");
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
        fetchData();
      });
    } catch (exception) {
      const response = handleActionException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  const handleConfirmSuggestTimlineDialog = async () => {
    log.trace("handleConfirmSuggestTimlineDialog.request");
    logEvent(eventSource, "handleConfirmSuggestTimlineDialog.request");

    handleCloseSuggestTimlineDialog();

    try {
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/suggestTimeline`,
            {
              bookUuid: bookId,
            },
            await requestHeaders()
          )
          .then(response => {
            log.trace(
              "handleConfirmSuggestTimlineDialog.response.data",
              response.data
            );
            logEvent(eventSource, "handleConfirmSuggestTimlineDialog.response");
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
        fetchData();
      });
    } catch (exception) {
      const response = handleActionException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  const handleOpenSuggestTimlineDialog = () => {
    log.trace("handleOpenSuggestTimlineDialog");
    if (timelines.length > 0) {
      setOpenSuggestTimelineDialog(true);
    } else {
      handleConfirmSuggestTimlineDialog();
    }
  };

  const handleCloseSuggestTimlineDialog = () => {
    log.trace("handleCloseSuggestTimlineDialog");
    setOpenSuggestTimelineDialog(false);
  };

  return (
    <PageContainer
      title=""
      breadcrumbs={[
        { title: t("text.overview"), path: `/console/books/${pageParams.id}` },
        {
          title: t("text.timeline"),
          path: `/console/books/${pageParams.id}/timeline`,
        },
      ]}
    >
      <Box component="section">
        {alertMessage.message && (
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        )}

        {loading ? (
          <LinearProgress />
        ) : (
          <Box component="section" sx={{ p: 2 }}>
            <ConfirmationDialog
              open={openSuggestTimelineDialog}
              onClose={handleCloseSuggestTimlineDialog}
              onConfirm={handleConfirmSuggestTimlineDialog}
              title={t("overwriteConfirmationDialog.timeline.title")}
              message={t("overwriteConfirmationDialog.timeline.message")}
              destructiveActionName={t(
                "overwriteConfirmationDialog.timeline.destructiveActionButton"
              )}
            />

            <ConfirmationDialog
              open={openDeleteDialog}
              onClose={handleCloseDeleteDialog}
              onConfirm={handleConfirmDeleteDialog}
              title={t("deleteConfirmationDialog.title")}
              message={t("deleteConfirmationDialog.timeline.message")}
              promptHelperText={t(
                "deleteConfirmationDialog.timeline.helperText"
              )}
              confirmationData={deleteDialogConfirmationData}
            />

            <TimelineDialog
              title={t("timelineDialog.title")}
              message={t("timelineDialog.message")}
              open={openTimelineDialog}
              onClose={handleCloseTimelineDialog}
              onContinue={handleContinueTimelineDialog}
              onDelete={handleDeleteTimelineDialog}
              timelineData={timeline}
            />

            <TimelineMUI
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0,
                },
              }}
            >
              {timelines.map((timeline, index) => {
                return (
                  <TimelineItem key={timeline.order}>
                    <TimelineOppositeContent color="text.secondary">
                      {index !== 0 && timelines.length > 1 && (
                        <ArrowDropUpIcon
                          className="timelineButtonHighlight"
                          onClick={e =>
                            handleMoveUpClick(e, timeline.uuid, index)
                          }
                          color="primary"
                          size="small"
                        />
                      )}
                      {index < timelines.length - 1 && (
                        <ArrowDropDownIcon
                          className="timelineButtonHighlight"
                          onClick={e =>
                            handleMoveDownClick(e, timeline.uuid, index)
                          }
                          color="primary"
                          size="small"
                        />
                      )}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot
                        color={index !== 0 ? "primary" : "success"}
                        variant="outlined"
                      ></TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent
                      className="timelineHighlight"
                      onClick={() => handleEventClick(timeline)}
                    >
                      <Typography variant="h6" component="span">
                        {timeline.title}
                      </Typography>
                      <Typography>{timeline.shortDescription}</Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </TimelineMUI>
            <Stack direction="row" sx={{ p: 2 }}>
              <Button
                onClick={handleCreateClick}
                variant="text"
                sx={{ width: "100", height: "100%" }}
              >
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AddCircleOutlineOutlinedIcon />
                  {t("text.createNewEvent").toLocaleUpperCase()}
                </Stack>
              </Button>
              {!bookData.isNonSequentialBook && (
                <Button
                  onClick={handleOpenSuggestTimlineDialog}
                  variant="text"
                  sx={{ width: "100", height: "100%" }}
                >
                  <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AutoFixHighIcon />
                    {t("buttonAction.suggestTimeline")}
                  </Stack>
                </Button>
              )}
            </Stack>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

export default Timeline;
