import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ForkRightIcon from "@mui/icons-material/ForkRight";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PeopleIcon from "@mui/icons-material/People";
import SchoolIcon from "@mui/icons-material/School";
import TimelineIcon from "@mui/icons-material/Timeline";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

function BookEditorLayout() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [pathname, setPathname] = useState(location.pathname);

  const router = useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: path => {
        setPathname(String(path));
        navigate(path);
      },
    };
  }, [navigate, pathname]);

  const [bookId, setBookId] = useState(pageParams.id);

  const [branding, setBranding] = useState({
    logo: "",
    title: location.state?.title ?? "LIBELL.US",
    isNonSequentialBook: location.state?.isNonSequentialBook,
  });

  const NAVIGATION = [
    {
      segment: "console/books",
      title: t("text.allBooks"),
      icon: <ArrowBackIosIcon />,
    },
    {
      kind: "header",
      title: t("text.home"),
    },
    {
      segment: "console/books/" + bookId,
      title: t("text.overview"),
      icon: <DashboardIcon />,
    },
    {
      kind: "divider",
    },
    {
      kind: "header",
      title: t("text.content"),
    },
    {
      segment: "console/books/" + bookId + "/pages",
      title: t("text.pages"),
      icon: <MenuBookIcon />,
    },
    {
      segment: "console/books/" + bookId + "/characters",
      title: t("text.characters"),
      icon: <PeopleIcon />,
    },
    {
      segment: "console/books/" + bookId + "/locations",
      title: t("text.locations"),
      icon: <AddLocationAltIcon />,
    },
    {
      segment: "console/books/" + bookId + "/timeline",
      title: t("text.timeline"),
      icon: <TimelineIcon />,
    },
  ];

  branding.isNonSequentialBook &&
    NAVIGATION.push({
      segment: "console/books/" + bookId + "/pathways",
      title: t("text.pathways"),
      icon: <ForkRightIcon />,
    });

  NAVIGATION.push({
    kind: "divider",
  });
  NAVIGATION.push({
    kind: "header",
    title: t("text.help"),
  });
  NAVIGATION.push({
    segment: "console/books/" + bookId + "/learningcenter",
    title: t("text.learningCenter"),
    icon: <SchoolIcon />,
  });

  return (
    // preview-start
    <AppProvider navigation={NAVIGATION} router={router} branding={branding}>
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    </AppProvider>
    // preview-end
  );
}

export default BookEditorLayout;
