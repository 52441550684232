import LanguageIcon from "@mui/icons-material/Language";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  ImageList,
  ImageListItem,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Slider,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import firebaseAuth from "../Services/auth";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (event, lang) => {
    log.trace("handleLanguageChange", event, lang);

    lang = lang ?? languageList.options[0];

    i18n.changeLanguage(lang.value);
    localStorage.setItem("i18nextLng", lang.value);
  };

  const languageList = {
    options: [
      { value: "en-US", code: "US", label: "English" },
      { value: "pt-BR", code: "BR", label: "Português" },
    ],
    getOptionLabel: option => option.label,
  };

  var currentLanguage = languageList.options.filter(
    lang => lang.value === i18n.language
  );
  currentLanguage =
    currentLanguage.length !== 0 ? currentLanguage[0] : languageList.options[0];

  return (
    <Autocomplete
      {...languageList}
      id="language-auto-complete"
      name="language-auto-complete"
      autoHighlight
      required
      fullWidth
      onChange={handleLanguageChange}
      value={currentLanguage}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key}
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...optionProps}
          >
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              alt=""
            />
            {option.label}
          </Box>
        );
      }}
      renderInput={language => (
        <TextField
          {...language}
          key={language.value}
          sx={{
            "& .MuiInputLabel-root": {
              color: "#fff", // Dark label color
            },
            "& .MuiInputBase-input": {
              color: "#fff", // Dark text color
            },
          }}
        />
      )}
    />
  );
};

export default LanguageSelector;
