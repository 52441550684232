import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  BlueskyIcon,
  BlueskyShareButton,
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  ThreadsIcon,
  ThreadsShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import log from "../Tools/Log";

const ShareDialog = ({ title, message, shareData, open, onClose }) => {
  const { t } = useTranslation();
  const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

  useEffect(() => {
    log.trace("useEffect", shareData);
  }, [open, shareData]);

  const handleDialogClose = e => {
    log.trace("handleDialogClose", e);
    onClose();
  };

  return (
    <>
      {open && (
        <Dialog open={open} onClose={handleDialogClose}>
          <Box component="section">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ pb: 2 }}>{message}</DialogContentText>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  justifyContent: "center",
                }}
              >
                <FacebookShareButton
                  url={shareData.shareUrl}
                  className="libellus.share"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>

                <TwitterShareButton
                  url={shareData.shareUrl}
                  title={shareData.shareMessageNoUrl}
                  className="libellus.share"
                >
                  <XIcon size={32} round />
                </TwitterShareButton>

                <TelegramShareButton
                  url={shareData.shareUrl}
                  title={shareData.shareMessageNoUrl}
                  className="libellus.share"
                >
                  <TelegramIcon size={32} round />
                </TelegramShareButton>

                <WhatsappShareButton
                  url={shareData.shareUrl}
                  title={shareData.shareMessageNoUrl}
                  separator=" - "
                  className="libellus.share"
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>

                <LinkedinShareButton
                  url={shareData.shareUrl}
                  className="libellus.share"
                >
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>

                <PinterestShareButton
                  url={shareData.shareUrl}
                  description={shareData.shareMessage}
                  media={shareData.imageUrl}
                  className="libellus.share"
                >
                  <PinterestIcon size={32} round />
                </PinterestShareButton>

                <RedditShareButton
                  url={shareData.shareUrl}
                  title={shareData.shareMessageNoUrl}
                  windowWidth={660}
                  windowHeight={460}
                  className="libellus.share"
                >
                  <RedditIcon size={32} round />
                </RedditShareButton>

                <TumblrShareButton
                  url={shareData.shareUrl}
                  caption={shareData.shareMessage}
                  title={shareData.shareMessageNoUrl}
                  className="libellus.share"
                >
                  <TumblrIcon size={32} round />
                </TumblrShareButton>

                <EmailShareButton
                  url={shareData.shareUrl}
                  subject={shareData.shareMessageNoUrl}
                  body=""
                  className="libellus.share"
                >
                  <EmailIcon size={32} round />
                </EmailShareButton>

                <ThreadsShareButton
                  url={shareData.shareUrl}
                  title={shareData.shareMessageNoUrl}
                  className="libellus.share"
                >
                  <ThreadsIcon size={32} round />
                </ThreadsShareButton>

                <BlueskyShareButton
                  url={shareData.shareUrl}
                  title={shareData.shareMessageNoUrl}
                  windowWidth={660}
                  windowHeight={460}
                  className="libellus.share"
                >
                  <BlueskyIcon size={32} round />
                </BlueskyShareButton>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default ShareDialog;
