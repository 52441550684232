import { Avatar } from "@mui/material";
import React from "react";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";

function Character() {
  return (
    <Avatar
      alt="libell.us"
      src="/libellus.png"
      sx={{
        width: 25,
        height: 25,
        display: { xs: "none", md: "flex" },
        mr: 1,
      }}
    />
  );
}

export default Character;
