import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Slider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { logEvent } from "../../Services/firebase";
import {
  copyDataToModel,
  formSubmitHeaders,
  requestHeaders,
} from "../../Tools/DataUtils";
import {
  handleActionException,
  handleDataFetchException,
} from "../../Tools/ErrorHandling";
import log from "../../Tools/Log";
import ImageGenerationDialog from "../../UiComponents/ImageGenerationDialog";
import ImagePicker from "../../UiComponents/ImagePicker";
import NoMaxWidthTooltip from "../../UiComponents/NoMaxWidthTooltip";
import DictionaryDefinition from "../Learning/DictionaryDefinition";
import LearningHierarchyOfNeeds from "../Learning/HierarchyOfNeeds";
import LearningPersonality from "../Learning/Personality";
import LearningPhysicalDetails from "../Learning/PhysicalDetails";
import LearningTraits from "../Learning/Traits";

function Character() {
  const eventSource = "Character";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [characterData, setCharacterData] = useState({
    uuid: "",
    bookUuid: "",
    name: "",
    role: "",
    backstory: "",
    hierarchyOfNeeds: 3,
    physical: {
      overview: "",
      height: "",
      weight: "",
      age: "",
      race: "",
      appearance: "",
      style: "",
    },
    personality: {
      opennessToExperience: [40, 60],
      conscientiousness: [40, 60],
      extraversion: [40, 60],
      agreeableness: [40, 60],
      emotionality: [40, 60],
      honestyHumility: [40, 60],
      narcissism: [40, 60],
      machiavellianism: [40, 60],
      psychopathy: [40, 60],
      cruelty: [40, 60],
    },
    traits: { positive: [], negative: [], quirks: [] },
    backgroundImage: "",
    imagePrompt: "",
    newBackgroundImage: null,
  });
  const [characterRoles, setCharacterRoles] = useState([]);
  const [negativeTraitsList, setNegativeTraitsList] = useState([]);
  const [positiveNeutralTraitsList, setPositiveNeutralTraitsList] = useState(
    []
  );
  const [hierarchyOfNeedsList, setHierarchyOfNeedsList] = useState([]);

  const [creatingNew, setCreatingNew] = useState(false);
  const [selectedTrait, setSelectedTrait] = useState([]);
  const [selectedHoN, setSelectedHoN] = useState({});
  const [openImageGenerationDialog, setOpenImageGenerationDialog] =
    useState(false);
  const [imageGenerationOptions, setImageGenerationOptions] = useState({
    imageService: {
      name: `${process.env.REACT_APP_DEFAULT_AI_IMAGE_SERVICE}`,
      endpoint: `${process.env.REACT_APP_DEFAULT_AI_IMAGE_SERVICE_ENDPOINT}`,
    },
    useLocation: true,
    useCharacters: true,
    useImageDescriptorService: true,
  });
  const [openImagePickerDialog, setOpenImagePickerDialog] = useState(false);
  const [imagePickerOptions, setImagePickerOptions] = useState({});

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    if (pageParams.cid == "new") {
      log.trace("creating new");
      setCreatingNew(true);
      setCharacterData({ ...characterData, bookUuid: pageParams.id });

      log.warn("hierarchyOfNeedsList[0]", hierarchyOfNeedsList[0]);
    }

    fetchData();
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      // TODO implement filters
      const filters = { characterUuid: pageParams.cid };

      requests.push(fetchCharacterSetupData());

      if (pageParams.cid != "new") {
        requests.push(
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/getCharacters`,
              {
                bookUuid: pageParams.id,
                filters,
              },
              headers
            )
            .then(response => {
              log.trace("fetchData.response.data", response.data);
              logEvent(eventSource, "fetchData.response");

              response.data.map(item => {
                item.imageUrl =
                  `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                  item.backgroundImage;
              });

              if (response.data[0]) {
                setCharacterData(response.data[0]);

                // TODO handle this during creation
                if (!response.data[0].physical) {
                  response.data[0].physical = {};
                }
              }
            })
        );
      }

      await Promise.all(requests)
        .then(() => {
          if (characterData.hierarchyOfNeeds != null) {
            const [item] = hierarchyOfNeedsList.filter(
              i => i.value == characterData.hierarchyOfNeeds
            );
            if (item != null) {
              setSelectedHoN(item);
            }
          }
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      const response = handleDataFetchException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  const fetchCharacterSetupData = async () => {
    log.trace("fetchCharacterSetupData.request");
    logEvent(eventSource, "fetchCharacterSetupData.request");

    try {
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/getCharacterSetupData`,
            {
              bookUuid: pageParams.id,
            },
            headers
          )
          .then(response => {
            log.trace("fetchData.response.data", response.data);
            logEvent(eventSource, "fetchData.response");

            if (response.data) {
              if (response.data.roles) {
                setCharacterRoles({
                  options: response.data.roles.sort((a, b) =>
                    a.localeCompare(b)
                  ),
                });
              }

              if (response.data.negativeTraits) {
                setNegativeTraitsList({
                  options: response.data.negativeTraits.sort((a, b) =>
                    a.localeCompare(b)
                  ),
                });
              }

              if (response.data.positiveNeutralTraits) {
                setPositiveNeutralTraitsList({
                  options: response.data.positiveNeutralTraits.sort((a, b) =>
                    a.localeCompare(b)
                  ),
                });
              }

              if (response.data.hierarchyOfNeedsMarks) {
                setHierarchyOfNeedsList(response.data.hierarchyOfNeedsMarks);
                setSelectedHoN(response.data.hierarchyOfNeedsMarks[0]);
              }
            }
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleDataFetchException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleFormSubmit = async e => {
    log.trace("handleFormSubmit.request", e, characterData);
    logEvent(eventSource, "handleFormSubmit.request");

    try {
      e.preventDefault();
      showLoadingUI();

      const requests = [];
      const headers = await formSubmitHeaders();

      const formData = new FormData();
      formData.append("bookUuid", pageParams.id);
      if (pageParams.cid != "new") formData.append("uuid", pageParams.cid);
      copyDataToModel(characterData, formData);

      if (creatingNew) {
        requests.push(
          axios.post(
            `${process.env.REACT_APP_API_URL}/putCharacter`,
            formData,
            headers
          )
        );
      } else {
        requests.push(
          axios.put(
            `${process.env.REACT_APP_API_URL}/putCharacter`,
            formData,
            await formSubmitHeaders()
          )
        );
      }

      await Promise.all(requests)
        .then(response => {
          log.trace("handleFormSubmit.response.data", response.data);
          logEvent(eventSource, "handleFormSubmit.response");

          setCharacterData({});
          navigate(`/console/books/${pageParams.id}/characters`);
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      const response = handleActionException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setCharacterData({ ...characterData, [name]: value });
  };

  const handleFileChange = e => {
    if (e.target.files[0]) {
      URL.revokeObjectURL(characterData.imageUrl);
      const previewImageUrl = URL.createObjectURL(e.target.files[0]);

      setCharacterData({
        ...characterData,
        newBackgroundImage: e.target.files[0],
        backgroundImage: e.target.files[0].name,
        imageUrl: previewImageUrl,
        imagePrompt: null,
      });
    }
  };

  const handleCancel = () => {
    log.trace("handleCancel");
    navigate(`/console/books/${pageParams.id}/characters`);
  };

  const handlePageBackgroundImageLoaded = e => {
    log.trace("handleBookCoverLoaded", e);
    // const img = e.target;
    // TODO warn of best options for image format, ration, etc
    // BE uses sharp; converts to web friendly format automatically
  };

  const handleRoleInputChange = (e, value) => {
    log.trace("handleRoleInputChange", e, value);
    setCharacterData({ ...characterData, role: value });
  };

  const handlePersonalityInputChange = e => {
    log.trace("handlePersonalityInputChange", e);
    const { name, value } = e.target;
    const personality = characterData.personality;
    personality[name] = value;
    setCharacterData({ ...characterData, personality: personality });
  };

  const handlePhysicalInputChange = e => {
    log.trace("handlePhysicalInputChange", e);
    const { name, value } = e.target;
    const physical = characterData.physical;
    physical[name] = value;
    setCharacterData({ ...characterData, physical: physical });
  };

  const handlePositiveTraitInputChange = (e, values) => {
    log.trace("handlePositiveTraitInputChange", e, values);
    const maxTraits = 5;
    if (values.length > maxTraits) {
      values.slice(0, maxTraits - 1);
      setAlertMessage({
        message: "You've reached the max number of traits.",
        severity: "warning", //success info warning error
      });
    } else {
      setAlertMessage({});
      characterData.traits.positive = values;
      setCharacterData({ ...characterData, traits: characterData.traits });
    }
  };

  const handleNegativeTraitInputChange = (e, values) => {
    log.trace("handleNegativeTraitInputChange", e, values);
    const maxTraits = 5;
    if (values.length > maxTraits) {
      values.slice(0, maxTraits - 1);
      setAlertMessage({
        message: "You've reached the max number of traits.",
        severity: "warning", //success info warning error
      });
    } else {
      setAlertMessage({});
      characterData.traits.negative = values;
      setCharacterData({ ...characterData, traits: characterData.traits });
    }
  };

  const handleTraitClick = (e, word) => {
    log.trace("handleTraitClick", word);
    setSelectedTrait(word);
  };

  const handleHierarchyOfNeedsInputChange = e => {
    log.trace("handleHierarchyOfNeedsInputChange", e);
    const { name, value } = e.target;
    setCharacterData({ ...characterData, [name]: value });

    const [item] = hierarchyOfNeedsList.filter(i => i.value == value);
    setSelectedHoN(item);
  };

  const suggestPortraitImageFromCharacterDescription =
    async selectedOptionsData => {
      log.trace(
        "suggestPortraitImageFromCharacterDescription.request",
        selectedOptionsData
      );
      logEvent(
        eventSource,
        "suggestPortraitImageFromCharacterDescription.request"
      );

      if (
        !characterData.physical.overview ||
        characterData.physical.overview.length == 0
      ) {
        setAlertMessage({
          message: t("error.suggestPortraitImageMissingParameters"),
          severity: "error",
        });

        return;
      }

      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      // TODO priority this should be a specific endpoint for this functionality; no client side logic
      // include personality characteristics and backstory for an accurate suggestion
      // genai to produce bg image
      try {
        const characterPhysicalData = [
          "Generate a portrait image for a character with the following characteristics. ",
        ];
        characterData.physical.overview &&
          characterPhysicalData.push(characterData.physical.overview + ".");

        characterData.physical.race &&
          characterPhysicalData.push(
            "With the race: " + characterData.physical.race + "."
          );
        characterData.physical.height &&
          characterPhysicalData.push(
            "With the height: " + characterData.physical.height + "."
          );
        characterData.physical.weight &&
          characterPhysicalData.push(
            "With the weight: " + characterData.physical.weight + "."
          );
        characterData.physical.age &&
          characterPhysicalData.push(
            "With the age: " + characterData.physical.age + "."
          );
        characterData.physical.appearance &&
          characterPhysicalData.push(
            "With the following appearance characteristics: " +
              characterData.physical.appearance +
              "."
          );
        characterData.physical.style &&
          characterPhysicalData.push(
            "With the visual style: " + characterData.physical.style + "."
          );

        requests.push(
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/${selectedOptionsData.imageService.endpoint}`,
              {
                bookUuid: pageParams.id,
                narrativePrompt: characterPhysicalData.join(" "),
                options: selectedOptionsData,
              },
              headers
            )
            .then(response => {
              log.trace(
                "suggestPortraitImageFromCharacterDescription.response.data",
                response.data
              );
              logEvent(
                eventSource,
                "suggestPortraitImageFromCharacterDescription.response"
              );

              const imageUrl =
                `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                response.data.filePath;

              //set form data with image generated. image is already stored, so just save the file path
              setCharacterData({
                ...characterData,
                imagePrompt: response.data.imagePrompt,
                backgroundImage: response.data.filePath,
                imageUrl: imageUrl,
              });
            })
        );

        await Promise.all(requests).finally(() => {
          hideLoadingUI();
        });
      } catch (exception) {
        const response = handleActionException(
          eventSource,
          exception,
          "error.imageGenerationGeneric"
        );
        if (response.isRedirect) {
          navigate(response.redirectUrl);
          return;
        }

        setAlertMessage({
          message: t(response.message),
          severity: "error", //success info warning error
        });

        hideLoadingUI();
      }
    };

  const handleOpenImageGenerationDialog = () => {
    log.trace("handleOpenImageGenerationDialog");
    logEvent(eventSource, "handleOpenImageGenerationDialog");

    // TODO how to properly handle no characters or location options
    setImageGenerationOptions({
      ...imageGenerationOptions,
      useCharacters: "hidden",
      useLocation: "hidden",
    });

    setOpenImageGenerationDialog(true);
  };

  const handleCloseImageGenerationDialog = selectedOptionsData => {
    log.trace("handleCloseImageGenerationDialog", selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);
    setOpenImageGenerationDialog(false);
  };

  const handleContinueImageGeneration = async selectedOptionsData => {
    log.trace("handleContinueImageGeneration", selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);
    setOpenImageGenerationDialog(false);
    suggestPortraitImageFromCharacterDescription(selectedOptionsData);
  };

  const handleOpenImagePickerDialog = () => {
    setOpenImagePickerDialog(true);
  };

  const handleCloseImagePickerDialog = selectedOptionsData => {
    log.trace("handleCloseImagePickerDialog", selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);
    setOpenImagePickerDialog(false);
  };

  const handleContinueImagePicker = async selectedOptionsData => {
    log.trace("handleContinueImagePicker", selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);
    setOpenImagePickerDialog(false);

    //from computer or from gallery, ...
    if (!selectedOptionsData) {
      log.trace("handleContinueImagePicker.fromComputer");
      logEvent(eventSource, "handleContinueImagePicker.fromComputer");
      imagePickerSelectFromComputer();
    } else {
      log.trace("handleContinueImagePicker.fromGallery");
      logEvent(eventSource, "handleContinueImagePicker.fromGallery");
      const filePath = selectedOptionsData.filePath;
      const imagePrompt = selectedOptionsData.imagePrompt;
      const imageUrl = selectedOptionsData.imageUrl;

      //set form data with image previously generated. image is already stored, so just get the file path
      setCharacterData({
        ...characterData,
        imagePrompt: imagePrompt,
        backgroundImage: filePath,
        imageUrl: imageUrl,
        newBackgroundImage: null,
      });
    }
  };

  const imagePickerSelectFromComputer = () => {
    log.trace("imagePickerSelectFromComputer");
    uploadInputRef.current && uploadInputRef.current.click();
  };

  /**
      VICE
      -------------------------------------------------------------------------------------------------
      https://en.wikipedia.org/wiki/Vice

      A vice is a practice, behaviour, or habit generally considered morally wrong in the associated 
      society. In more minor usage, vice can refer to a fault, a negative character trait, a defect, 
      an infirmity, or a bad or unhealthy habit.[citation needed] Vices are usually associated with a 
      fault in a person's character or temperament rather than their morality.

      The modern English term that best captures its original meaning is the word vicious, which means 
      "full of vice". In this sense, the word vice comes from the Latin word vitium, meaning 
      "failing or defect"

      VIRTUE
      -------------------------------------------------------------------------------------------------
      https://en.wikipedia.org/wiki/Virtue

      A virtue (Latin: virtus) is a trait of excellence, including traits that may be moral, social, 
      or intellectual. The cultivation and refinement of virtue is held to be the "good of humanity" 
      and thus is valued as an end purpose of life or a foundational principle of being.

      The ancient Romans used the Latin word virtus (derived from vir, their word for man) to refer 
      to all of the "excellent qualities of men, including physical strength, valorous conduct, and 
      moral rectitude". The French words vertu and virtu came from this Latin root. The word virtue 
      "was borrowed into English in the 13th century".

      ETHICS
      -------------------------------------------------------------------------------------------------
      https://en.wikipedia.org/wiki/Virtue_ethics

      Virtues are not everyday habits; they are character traits, in the sense that they are central 
      to someone’s personality and what they are like as a person.

      Virtue ethics is usually contrasted with two other major approaches in ethics, consequentialism 
      and deontology, which make the goodness of outcomes of an action (consequentialism) and the 
      concept of moral duty (deontology) central

      virtue was not a skill that made you better able to achieve eudaimonia but was itself an 
      expression of eudaimonia.

      incontinent (who are tempted by their feelings into doing the wrong thing even though they know 
      what is right)
      continent (whose emotions tempt them toward doing the wrong thing but whose strength of will 
      lets them do what they know is right)

      RELIGION
      -------------------------------------------------------------------------------------------------
      cardinal virtues of prudence, justice, temperance, and fortitude
      theological virtues of faith, hope, and charity
      
      chastity, temperance, charity, diligence, kindness, patience, and humility
      pride, greed, wrath, envy, lust, gluttony and sloth

      PLATONIC VIRTUE
      -------------------------------------------------------------------------------------------------
      The four classic cardinal virtues are:

      Prudence (φρόνησις, phrónēsis; Latin: prudentia; also Wisdom, Sophia, sapientia), the ability to 
      discern the appropriate course of action to be taken in a given situation at the appropriate time.
      
      Fortitude (ἀνδρεία, andreía; Latin: fortitudo): also termed courage, forbearance, strength, 
      endurance, and the ability to confront fear, uncertainty, and intimidation.
      
      Temperance (σωφροσύνη, sōphrosýnē; Latin: temperantia): also known as restraint, the practice of 
      self-control, abstention, discretion, and moderation tempering the appetition. Plato considered 
      sōphrosynē, which may also be translated as sound-mindedness, to be the most important virtue.
      
      Justice (δικαιοσύνη, dikaiosýnē; Latin: iustitia): also considered as fairness;[6] the Greek word 
      also having the meaning of righteousness.

      Temperance was most closely associated with the producing classes, the farmers and craftsmen, 
      to moderate their animal appetites. Fortitude was assigned to the warrior class, to strengthen 
      their fighting spirit. Prudence was assigned to the rulers, to guide their reason. Justice 
      stood above these three to properly regulate the relations among them.

      Aristotle's Rhetoric: The forms of Virtue are justice, courage, temperance, magnificence, 
      magnanimity, liberality, gentleness, prudence, wisdom.

      HEXACO
      -------------------------------------------------------------------------------------------------
      https://hexaco.org/
      HEXACO and Virtue - https://www.psychologytoday.com/us/blog/ethics-everyone/201304/hexaco-and-virtue

      Honesty-Humility                Justice/Fairness 
      Emotionality                    Fortitude/Courage 
      eXtraversion                    
      Agreeableness (versus Anger)    
      Conscientiousness               Temperance/Restraint
      Openness to Experience          Prudence/Wisdom 

      DARK TRIAD/TETRAD
      -------------------------------------------------------------------------------------------------
      Machiavellianism: A tendency to manipulate others for self-interest, often through deception and a 
      lack of morality or emotion. The term comes from the 16th-century Italian diplomat and politician 
      Niccolo Machiavelli, whose book The Prince was interpreted as endorsing cunning and deceit in 
      diplomacy.

      Narcissism: A tendency to have an excessive sense of self-importance and grandiosity. People with 
      narcissistic tendencies may be charming, but they can also be political, manipulative, and lack 
      remorse. 

      Psychopathy: A tendency to be impulsive, callous, and thrill seeking. 

      Sadism/Cruelty: delight in cruelty; the derivation of gratification from the infliction of physical pain 
      or humiliation on another person
   */

  /**
    Maslow's hierarchy of needs
    //1. Biological and physiological needs - air, food, drink, shelter, warmth, sex, sleep, etc. [homeostasis]
    //2. Safety needs - protection from elements, security, order, law, stability, freedom from fear.
    //3. Love and belongingness needs - friendship, intimacy, trust, and acceptance, receiving and giving affection 
          and love.Affiliating, being part of a group(family, friends, work).
    //4. Esteem needs - which Maslow classified into two categories: (i) esteem for oneself(dignity, achievement, 
          mastery, independence) and(ii) the need to be accepted and valued by others(e.g., status, prestige).
    //5. Cognitive needs - knowledge and understanding, curiosity, exploration, need for meaning and predictability.
    //6. Aesthetic needs - appreciation and search for beauty, balance, form, etc.
    //7. Self-actualization needs - realizing personal potential, self-fulfillment, seeking personal growth and peak 
          experiences.A desire to become everything one is capable of becoming (Maslow, 1987, p. 64).
    //8. Transcendence needs - A person is motivated by values which transcend beyond the personal self(e.g., 
          mystical experiences and certain experiences with nature, aesthetic experiences, sexual experiences, 
          service to others, the pursuit of science, religious faith, etc.).
  */

  function hierarchyOfNeedsMarkText(mark) {
    log.trace("hierarchyOfNeedsMarkText", mark);
    return `${mark.label}`;
  }

  const handleRemoveImage = () => {
    log.trace("handleRemoveImage");
    setCharacterData({
      ...characterData,
      backgroundImage: "",
      imageUrl: "",
      newBackgroundImage: null,
    });
  };

  const uploadInputRef = useRef(null);

  return (
    <PageContainer
      title=""
      breadcrumbs={[
        { title: t("text.overview"), path: `/console/books/${pageParams.id}` },
        {
          title: t("text.characters"),
          path: `/console/books/${pageParams.id}/characters`,
        },
        {
          title: t("text.character"),
          path: `/console/books/${pageParams.id}/characters/${pageParams.cid}`,
        },
      ]}
    >
      <Box component="section">
        {alertMessage.message && (
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        )}

        {loading ? (
          <LinearProgress />
        ) : (
          <Box component="section" sx={{ p: 2 }}>
            <ImageGenerationDialog
              title={t("imageGenerationDialog.title")}
              message={t("imageGenerationDialog.message")}
              open={openImageGenerationDialog}
              onClose={handleCloseImageGenerationDialog}
              onContinue={handleContinueImageGeneration}
              optionsData={imageGenerationOptions}
            />

            <ImagePicker
              title={t("imagePicker.title")}
              message={t("imagePicker.message")}
              open={openImagePickerDialog}
              onClose={handleCloseImagePickerDialog}
              onContinue={handleContinueImagePicker}
              optionsData={imagePickerOptions}
            />

            <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 1 }}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="char-details-content"
                  id="char-details-header"
                >
                  {t("header.characterDetails")}
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2} direction="column" sx={{ p: 2 }}>
                    <Stack spacing={2} direction="row" sx={{}}>
                      <TextField
                        sx={{ width: "50%" }}
                        id="name"
                        name="name"
                        required
                        autoFocus
                        value={characterData.name}
                        onChange={handleInputChange}
                        label={t("text.name")}
                        variant="outlined"
                      />
                      <Autocomplete
                        sx={{ width: "50%" }}
                        {...characterRoles}
                        id="role-auto-complete"
                        name="role-auto-complete"
                        autoComplete
                        filterSelectedOptions
                        onChange={handleRoleInputChange}
                        value={characterData.role}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { key, ...tagProps } = getTagProps({ index });
                            return (
                              <Chip
                                variant="filled"
                                label={option}
                                key={key}
                                {...tagProps}
                              />
                            );
                          })
                        }
                        renderInput={trait => (
                          <TextField
                            {...trait}
                            key={trait}
                            variant="standard"
                            label={t("text.role")}
                          />
                        )}
                      />
                    </Stack>
                    <TextField
                      id="backstory"
                      name="backstory"
                      required
                      fullWidth
                      multiline
                      maxRows={20}
                      value={characterData.backstory}
                      onChange={handleInputChange}
                      label={t("text.backstory")}
                      variant="outlined"
                    />
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="char-physical-details-content"
                  id="char-physical-details-header"
                >
                  <NoMaxWidthTooltip arrow title={<LearningPhysicalDetails />}>
                    {t("header.physicalDetails")}
                  </NoMaxWidthTooltip>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2} direction="column" sx={{ p: 2 }}>
                    <TextField
                      id="overview"
                      name="overview"
                      fullWidth
                      multiline
                      maxRows={20}
                      value={characterData.physical.overview}
                      onChange={handlePhysicalInputChange}
                      label={t("text.overview")}
                      variant="outlined"
                    />
                    <TextField
                      id="age"
                      name="age"
                      value={characterData.physical.age}
                      onChange={handlePhysicalInputChange}
                      label={t("text.age")}
                      variant="outlined"
                      type="number"
                    />
                    <TextField
                      id="height"
                      name="height"
                      value={characterData.physical.height}
                      onChange={handlePhysicalInputChange}
                      label={t("text.height")}
                      variant="outlined"
                    />
                    <TextField
                      id="weight"
                      name="weight"
                      value={characterData.physical.weight}
                      onChange={handlePhysicalInputChange}
                      label={t("text.weight")}
                      variant="outlined"
                    />
                    <TextField
                      id="race"
                      name="race"
                      value={characterData.physical.race}
                      onChange={handlePhysicalInputChange}
                      label={t("text.race")}
                      variant="outlined"
                    />
                    <TextField
                      id="appearance"
                      name="appearance"
                      fullWidth
                      multiline
                      maxRows={20}
                      value={characterData.physical.appearance}
                      onChange={handlePhysicalInputChange}
                      label={t("text.appearanceDetails")}
                      variant="outlined"
                    />
                    <TextField
                      id="style"
                      name="style"
                      fullWidth
                      multiline
                      maxRows={20}
                      value={characterData.physical.style}
                      onChange={handlePhysicalInputChange}
                      label={t("text.clothingStyleDetails")}
                      variant="outlined"
                    />
                    <Button
                      variant="text"
                      onClick={handleOpenImageGenerationDialog}
                    >
                      {t("buttonAction.suggestPortraitImage")}
                    </Button>
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="personality-content"
                  id="personality-header"
                >
                  <NoMaxWidthTooltip arrow title={<LearningPersonality />}>
                    {t("header.personality")}
                  </NoMaxWidthTooltip>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column" spacing={2}>
                    <Stack direction="row" spacing={2}>
                      <Tooltip
                        placement="right-start"
                        title={t(
                          "view.character.personalityExamples.deceitful"
                        )}
                      >
                        <Typography
                          variant="p"
                          sx={{ textAlign: "right", width: "16em" }}
                        >
                          {t("view.character.text.deceitful")}
                        </Typography>
                      </Tooltip>
                      <Slider
                        id="honestyHumility"
                        name="honestyHumility"
                        value={characterData.personality.honestyHumility}
                        onChange={handlePersonalityInputChange}
                        valueLabelDisplay="auto"
                      />
                      <Tooltip
                        placement="left-start"
                        title={t("view.character.personalityExamples.sincere")}
                      >
                        <Typography variant="p" sx={{ width: "16em" }}>
                          {t("view.character.text.sincere")}
                        </Typography>
                      </Tooltip>
                    </Stack>

                    <Stack direction="row" spacing={2}>
                      <Tooltip
                        placement="right-start"
                        title={t(
                          "view.character.personalityExamples.selfRestraint"
                        )}
                      >
                        <Typography
                          variant="p"
                          sx={{ textAlign: "right", width: "16em" }}
                        >
                          {t("view.character.text.selfRestraint")}
                        </Typography>
                      </Tooltip>
                      <Slider
                        id="emotionality"
                        name="emotionality"
                        value={characterData.personality.emotionality}
                        onChange={handlePersonalityInputChange}
                        valueLabelDisplay="auto"
                      />
                      <Tooltip
                        placement="left-start"
                        title={t(
                          "view.character.personalityExamples.sentimental"
                        )}
                      >
                        <Typography variant="p" sx={{ width: "16em" }}>
                          {t("view.character.text.sentimental")}
                        </Typography>
                      </Tooltip>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <Tooltip
                        placement="right-start"
                        title={t(
                          "view.character.personalityExamples.introvert"
                        )}
                      >
                        <Typography
                          variant="p"
                          sx={{ textAlign: "right", width: "16em" }}
                        >
                          {t("view.character.text.introvert")}
                        </Typography>
                      </Tooltip>
                      <Slider
                        id="extraversion"
                        name="extraversion"
                        value={characterData.personality.extraversion}
                        onChange={handlePersonalityInputChange}
                        valueLabelDisplay="auto"
                      />
                      <Tooltip
                        placement="left-start"
                        title={t(
                          "view.character.personalityExamples.extrovert"
                        )}
                      >
                        <Typography variant="p" sx={{ width: "16em" }}>
                          {t("view.character.text.extrovert")}
                        </Typography>
                      </Tooltip>
                    </Stack>

                    <Stack direction="row" spacing={2}>
                      <Tooltip
                        placement="right-start"
                        title={t(
                          "view.character.personalityExamples.cantankerousness"
                        )}
                      >
                        <Typography
                          variant="p"
                          sx={{ textAlign: "right", width: "16em" }}
                        >
                          {t("view.character.text.cantankerousness")}
                        </Typography>
                      </Tooltip>
                      <Slider
                        id="agreeableness"
                        name="agreeableness"
                        value={characterData.personality.agreeableness}
                        onChange={handlePersonalityInputChange}
                        valueLabelDisplay="auto"
                      />
                      <Tooltip
                        placement="left-start"
                        title={t(
                          "view.character.personalityExamples.obsequious"
                        )}
                      >
                        <Typography variant="p" sx={{ width: "16em" }}>
                          {t("view.character.text.obsequious")}
                        </Typography>
                      </Tooltip>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <Tooltip
                        placement="right-start"
                        title={t(
                          "view.character.personalityExamples.impulsive"
                        )}
                      >
                        <Typography
                          variant="p"
                          sx={{ textAlign: "right", width: "16em" }}
                        >
                          {t("view.character.text.impulsive")}
                        </Typography>
                      </Tooltip>
                      <Slider
                        id="conscientiousness"
                        name="conscientiousness"
                        value={characterData.personality.conscientiousness}
                        onChange={handlePersonalityInputChange}
                        valueLabelDisplay="auto"
                      />
                      <Tooltip
                        placement="left-start"
                        title={t("view.character.personalityExamples.diligent")}
                      >
                        <Typography variant="p" sx={{ width: "16em" }}>
                          {t("view.character.text.diligent")}
                        </Typography>
                      </Tooltip>
                    </Stack>

                    <Stack direction="row" spacing={2}>
                      <Tooltip
                        placement="right-start"
                        title={t(
                          "view.character.personalityExamples.comfortZone"
                        )}
                      >
                        <Typography
                          variant="p"
                          sx={{ textAlign: "right", width: "16em" }}
                        >
                          {t("view.character.text.comfortZone")}
                        </Typography>
                      </Tooltip>
                      <Slider
                        id="opennessToExperience"
                        name="opennessToExperience"
                        value={characterData.personality.opennessToExperience}
                        onChange={handlePersonalityInputChange}
                        valueLabelDisplay="auto"
                      />
                      <Tooltip
                        placement="left-start"
                        title={t(
                          "view.character.personalityExamples.curiosity"
                        )}
                      >
                        <Typography variant="p" sx={{ width: "16em" }}>
                          {t("view.character.text.curiosity")}
                        </Typography>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="traits-content"
                  id="traits-header"
                >
                  <NoMaxWidthTooltip arrow title={<LearningTraits />}>
                    {t("header.traits")}
                  </NoMaxWidthTooltip>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column" spacing={2}>
                    {selectedTrait.length > 0 && (
                      <DictionaryDefinition
                        language={"en"}
                        word={selectedTrait}
                        partOfSpeech={["adjective"]}
                      />
                    )}

                    <Autocomplete
                      {...positiveNeutralTraitsList}
                      id="positive-trait-auto-complete"
                      name="positive-trait-auto-complete"
                      fullWidth
                      autoComplete
                      multiple
                      filterSelectedOptions
                      onChange={handlePositiveTraitInputChange}
                      value={characterData.traits.positive}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              variant="filled"
                              label={option}
                              key={key}
                              onClick={e => handleTraitClick(e, option)}
                              {...tagProps}
                            />
                          );
                        })
                      }
                      renderInput={trait => (
                        <TextField
                          {...trait}
                          key={trait}
                          variant="standard"
                          label={t("text.positiveNeutral")}
                        />
                      )}
                    />
                    <Autocomplete
                      {...negativeTraitsList}
                      id="negative-trait-auto-complete"
                      name="negative-trait-auto-complete"
                      fullWidth
                      autoComplete
                      multiple
                      filterSelectedOptions
                      onChange={handleNegativeTraitInputChange}
                      value={characterData.traits.negative}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              variant="outlined"
                              label={option}
                              key={key}
                              onClick={e => handleTraitClick(e, option)}
                              {...tagProps}
                            />
                          );
                        })
                      }
                      renderInput={trait => (
                        <TextField
                          {...trait}
                          key={trait}
                          variant="standard"
                          label={t("text.negative")}
                        />
                      )}
                    />
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="images-content"
                  id="images-header"
                >
                  <NoMaxWidthTooltip arrow title={<LearningHierarchyOfNeeds />}>
                    {t("header.needs")}
                  </NoMaxWidthTooltip>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="row" spacing={2}>
                    <Slider
                      id="hierarchyOfNeeds"
                      name="hierarchyOfNeeds"
                      value={characterData.hierarchyOfNeeds}
                      shiftStep={1}
                      step={1}
                      orientation="vertical"
                      valueLabelDisplay="auto"
                      aria-label="Hierarchy of Needs"
                      marks={hierarchyOfNeedsList}
                      getAriaValueText={hierarchyOfNeedsMarkText}
                      min={1}
                      max={8}
                      onChange={handleHierarchyOfNeedsInputChange}
                      sx={{ height: 300 }}
                    />
                    <Box sx={{ pl: 30 }}>
                      <Card variant="outlined" sx={{ maxWidth: 700 }}>
                        <CardContent>
                          <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                            {selectedHoN.label}
                          </Typography>
                          <List>
                            <ListItem>
                              <ListItemText
                                primary={selectedHoN.description}
                                secondary={selectedHoN.examples}
                              />
                            </ListItem>
                          </List>
                        </CardContent>
                      </Card>
                    </Box>
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="images-content"
                  id="images-header"
                >
                  {t("header.media")}
                </AccordionSummary>
                <AccordionDetails>
                  <Box component="section" sx={{ p: 2 }}>
                    <Stack direction="row" justifyContent="center" spacing={2}>
                      <Button
                        onClick={handleOpenImagePickerDialog}
                        variant="contained"
                      >
                        {t("buttonAction.selectPortraitImage")}
                      </Button>
                      {characterData.backgroundImage && (
                        <IconButton
                          onClick={handleRemoveImage}
                          color="error"
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Stack>

                    {characterData.backgroundImage && (
                      <Box sx={{ p: 2 }}>
                        <Stack direction="column">
                          <Avatar
                            alt={characterData.backgroundImage}
                            src={characterData.imageUrl}
                            variant="square"
                            sx={{
                              width: "auto",
                              height: "100%",
                            }}
                            onLoad={handlePageBackgroundImageLoaded}
                          />
                          <Typography
                            variant="body2"
                            id="backgroundImage"
                            name="backgroundImage"
                            sx={{ pt: 2, textAlign: "right" }}
                          >
                            {characterData.backgroundImage}
                          </Typography>
                        </Stack>
                      </Box>
                    )}
                    <input
                      id="newBackgroundImage"
                      name="newBackgroundImage"
                      ref={uploadInputRef}
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Stack spacing={2} direction="row" justifyContent="center">
                <Button variant="contained" type="submit" color="primary">
                  {t("buttonAction.save")}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancel}
                >
                  {t("buttonAction.cancel")}
                </Button>
              </Stack>
            </Box>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

export default Character;
