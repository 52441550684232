import { Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import log from "../Tools/Log";

const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  message,
  promptHelperText,
  confirmationData,
  destructiveActionName,
}) => {
  const { t } = useTranslation();

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userTypedPrompt, setUserTypedPrompt] = useState("");

  const handleConfirmation = e => {
    log.trace("handleConfirmation", e);
    e.preventDefault();

    log.trace(userTypedPrompt);

    if (!confirmationData || !confirmationData.title) {
      onConfirm();
    } else if (userTypedPrompt == confirmationData.title) {
      onConfirm();
    }
  };

  const handleValidation = value => {
    log.trace("handleValidation", value);
    setUserTypedPrompt(value);

    if (value == confirmationData.title) {
      setIsError(false);
      setErrorMessage("");
    } else {
      setIsError(true);
      setErrorMessage(promptHelperText);
    }
  };

  return (
    <>
      {open && (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
            {confirmationData && confirmationData.title && (
              <TextField
                autoFocus
                required
                margin="dense"
                id="confirmationPrompt"
                name="confirmationPrompt"
                label={confirmationData.title}
                type="text"
                fullWidth
                error={isError}
                helperText={errorMessage}
                onChange={event => handleValidation(event.target.value)}
                variant="standard"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmation} color="error">
              {destructiveActionName || t("buttonAction.delete")}
            </Button>
            <Button onClick={onClose} color="secondary">
              {t("buttonAction.cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ConfirmationDialog;
