import { Box, Typography } from "@mui/material";
import React from "react";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";

function SubscribeCancel() {
  return (
    <Box component="section" sx={{}}>
      <Typography variant="h6">
        The subscription purchase was canceled. Please try again or contact us
        if you need assistance.
      </Typography>
    </Box>
  );
}

export default SubscribeCancel;
