import firebaseAuth from "../Services/auth";
import log from "./Log";

export function truncateIfNeeded(str, maxLength = 120) {
  return str.length > maxLength ? str.substring(0, maxLength - 1) + "…" : str;
}

export function sanitizeInputPrompt(str) {
  //TODO
  return str;
}

export function getShareUrl(book) {
  return "https://libell.us/catalog/" + book.uuid;
}

export async function requestHeaders() {
  const headers = {
    headers: {
      "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${await firebaseAuth.currentUser.getIdToken()}`,
    },
  };

  return headers;
}

export async function formSubmitHeaders() {
  const headers = {
    headers: {
      "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${await firebaseAuth.currentUser.getIdToken()}`,
    },
  };

  return headers;
}

export function getUserId() {
  return firebaseAuth.currentUser.uid;
}

export function stringToColor(string) {
  if (string === undefined || string === null) {
    string = "Anonymous";
  }
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  if (name === undefined || name === null || name.length < 2) {
    name = "Anonymous";
  } else {
    const parts = name.split(" ");
    if (parts.length > 1) {
      name = parts[0][0] + parts[1][0];
    } else {
      name = parts[0][0] + parts[0][1];
    }
  }

  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 130,
      height: 130,
    },
    children: name,
  };
}

/**
 * Copy all source properties to the dataModel
 * @param {*} source input data
 * @param {*} dataModel data model to store data into
 * @param {string[]} excludedFields [Optional, []] fields to be excluded
 * @param {boolean} overwrite [Optional, false] if true, overwrites existing fields
 */
export function copyDataToModel(
  source,
  dataModel,
  excludedProperties = [],
  overwrite = false
) {
  log.trace(
    "copyDataToModel",
    source,
    dataModel,
    excludedProperties,
    overwrite
  );

  // set each key/value pair in the FormData object, converting objects to JSON strings as needed
  Object.keys(source).forEach(key => {
    if (excludedProperties.includes(key)) {
      return;
    }

    if (source[key] !== undefined && source[key] != null) {
      let value = source[key];
      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value) &&
        !(value instanceof Date) &&
        !(value instanceof File)
      ) {
        log.trace("copyDataToModel stringify", value, JSON.stringify(value));
        value = JSON.stringify(value);
      }
      if (dataModel instanceof FormData) {
        if (overwrite || !dataModel.has(key)) {
          dataModel.append(key, value);
        }
      } else {
        if (overwrite || !Object.hasOwn(dataModel, key)) {
          dataModel[key] = value;
        }
      }
    }
  });
}
