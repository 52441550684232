import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { logEvent } from "../Services/firebase";
import { requestHeaders } from "../Tools/DataUtils";
import { handleDataFetchException } from "../Tools/ErrorHandling";
import log from "../Tools/Log";

const ImageGenerationDialog = ({
  title,
  message,
  open,
  onClose,
  onContinue,
  optionsData,
}) => {
  const eventSource = "ImageGenerationDialog";
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState({
    useLocation: false,
    useCharacters: false,
    useImageDescriptorService: false,
    userInclusions: "",
    userExclusions: "",
  });
  const [imageGeneratorServiceList, setImageGeneratorServiceList] = useState(
    {}
  );

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");

    setOptions(optionsData);

    if (open) {
      fetchData();
    }
  }, [open, optionsData]);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/getImageGenerationOptions`,
            {},
            headers
          )
          .then(imageResponse => {
            log.trace("fetchData.response.data", imageResponse.data);
            logEvent(eventSource, "fetchData.response");

            setImageGeneratorServiceList({
              options: imageResponse.data.sort((a, b) =>
                a.name.localeCompare(b.name)
              ),
            });
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleDataFetchException(eventSource, exception);
      hideLoadingUI();
    }
  };

  const handleConfirmation = e => {
    log.trace("handleConfirmation", e);
    onContinue(options);
  };

  const handleDialogClose = e => {
    log.trace("handleDialogClose", e);
    onClose(options);
  };

  const handleSwitchInputChange = e => {
    log.trace("handleSwitchInputChange", e);
    const { name, checked } = e.target;
    setOptions({ ...options, [name]: checked });
  };

  const handleImageServiceInputChange = (event, value) => {
    log.trace("handleImageServiceInputChange", event, value);

    // if value in imageGeneratorServiceList.options setOptions
    if (imageGeneratorServiceList.options.includes(value)) {
      setOptions({ ...options, imageService: value });
    }
  };

  const handleInputChange = e => {
    log.trace("handleInputChange", e);
    const { name, value } = e.target;
    setOptions({ ...options, [name]: value });
  };

  // // TODO priority should come from BE db
  // const imageGeneratorServicesList = {
  //   options: [
  //     { name: "Amazon AWS (Nova)", endpoint: "imageIdeationBasedOnPromptAWS" },
  //     {
  //       name: "Google (Imagen)",
  //       endpoint: "imageIdeationBasedOnPromptGAI",
  //     },
  //     // {
  //     //   name: "Google (VertexAI Imagen)",
  //     //   endpoint: "imageIdeationBasedOnPromptVAI",
  //     // },
  //     {
  //       name: "Microsoft (Azure OpenAI)",
  //       endpoint: "imageIdeationBasedOnPromptAzure",
  //     },
  //     { name: "OpenAI (DALL·E)", endpoint: "imageIdeationBasedOnPromptDalle" },
  //   ],
  //   getOptionLabel: option => option.name,
  // };

  return (
    <>
      {open && (
        <Dialog open={open} onClose={handleDialogClose}>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 10 }}>
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <>
              <DialogTitle>{title}</DialogTitle>
              <DialogContent>
                <DialogContentText sx={{ mb: 2 }}>{message}</DialogContentText>
                <FormGroup>
                  <Stack direction="column" spacing={2}>
                    <Autocomplete
                      {...imageGeneratorServiceList}
                      getOptionLabel={option => option.name}
                      id="image-services-auto-complete"
                      name="image-services-auto-complete"
                      fullWidth
                      autoComplete
                      onChange={handleImageServiceInputChange}
                      value={options.imageService}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              variant="filled"
                              label={option.name}
                              key={key}
                              {...tagProps}
                            />
                          );
                        })
                      }
                      renderInput={service => (
                        <TextField
                          {...service}
                          key={service.name}
                          variant="standard"
                          label={t(
                            "view.imageGenerationDialog.text.imageService"
                          )}
                        />
                      )}
                    />
                    {options.useLocation !== "hidden" && (
                      <FormControlLabel
                        control={
                          <Switch
                            name="useLocation"
                            id="useLocation"
                            checked={options.useLocation}
                            onChange={handleSwitchInputChange}
                          />
                        }
                        label={t("view.imageGenerationDialog.text.useLocation")}
                      />
                    )}
                    {options.useCharacters !== "hidden" && (
                      <FormControlLabel
                        control={
                          <Switch
                            name="useCharacters"
                            id="useCharacters"
                            checked={options.useCharacters}
                            onChange={handleSwitchInputChange}
                          />
                        }
                        label={t(
                          "view.imageGenerationDialog.text.useCharacters"
                        )}
                      />
                    )}

                    <FormControlLabel
                      control={
                        <Switch
                          name="useImageDescriptorService"
                          id="useImageDescriptorService"
                          checked={options.useImageDescriptorService}
                          onChange={handleSwitchInputChange}
                        />
                      }
                      label={t(
                        "view.imageGenerationDialog.text.use2StepsGenerator"
                      )}
                    />

                    <TextField
                      id="userInclusions"
                      name="userInclusions"
                      fullWidth
                      multiline
                      maxRows={10}
                      value={options.userInclusions}
                      onChange={handleInputChange}
                      label={t(
                        "view.imageGenerationDialog.text.userInclusions"
                      )}
                      variant="outlined"
                    />

                    <TextField
                      id="userExclusions"
                      name="userExclusions"
                      fullWidth
                      multiline
                      maxRows={10}
                      value={options.userExclusions}
                      onChange={handleInputChange}
                      label={t(
                        "view.imageGenerationDialog.text.userExclusions"
                      )}
                      variant="outlined"
                    />
                  </Stack>
                </FormGroup>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleConfirmation} color="primary">
                  {t("buttonAction.continue")}
                </Button>
                <Button onClick={handleDialogClose} color="secondary">
                  {t("buttonAction.cancel")}
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      )}
    </>
  );
};

export default ImageGenerationDialog;
