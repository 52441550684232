import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ImageList,
  ImageListItem,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { requestHeaders } from "../Tools/DataUtils";
import { handleDataFetchException } from "../Tools/ErrorHandling";
import log from "../Tools/Log";

const ImagePicker = ({
  title,
  message,
  open,
  onClose,
  onContinue,
  optionsData,
}) => {
  const eventSource = "ImagePicker";

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [assetsList, setAssetsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState();

  const [selectedAssetsList, setSelectedAssetsList] = useState([]);

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage();
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  const fetchData = async async => {
    log.trace("fetchData");

    try {
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      const filters = { genType: "img" };

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/getAssets`,
            {
              filters,
            },
            headers
          )
          .then(response => {
            response.data.map(item => {
              item.imageUrl =
                `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                item.filePath;
            });

            log.trace("response.data", response.data);
            log.trace(response.data);

            if (response.data) {
              setAssetsList(response.data);
            }
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleDataFetchException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  const handleClose = e => {
    onClose();
  };

  const handleConfirmation = (e, image) => {
    onContinue(image);
  };

  const handleToggleSelection = (e, image) => {
    e.stopPropagation();
    log.trace("handleToggleSelection", image);

    // add to selectedAssetsList
    if (!selectedAssetsList.includes(image)) {
      setSelectedAssetsList([...selectedAssetsList, image]);
    } else {
      // remove from selectedAssetsList
      setSelectedAssetsList(selectedAssetsList.filter(item => item !== image));
    }
  };

  const handleDeleteSelected = async e => {
    log.trace("handleDeleteSelected");

    try {
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/deleteAssets`,
            {
              assetsUuids: selectedAssetsList.map(item => item.uuid),
            },
            headers
          )
          .then(response => {
            log.trace("response.data", response.data);
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
        setSelectedAssetsList([]);
        fetchData();
      });
    } catch (exception) {
      const response = handleDataFetchException(eventSource, exception);

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  return (
    <>
      {open && (
        <Dialog
          fullWidth={false}
          maxWidth={800}
          open={open}
          onClose={handleClose}
        >
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 10 }}>
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <>
              <DialogTitle>{title}</DialogTitle>
              <DialogContent>
                <DialogContentText>{message}</DialogContentText>
                {assetsList.length > 0 && (
                  <ImageList
                    variant="masonry"
                    cols={Math.min(9, 3 + Math.floor(assetsList.length / 3))}
                    gap={8}
                  >
                    {assetsList.map(asset => (
                      <ImageListItem
                        key={asset.uuid}
                        sx={{
                          position: "relative",
                          "&:hover .selection-icon": { display: "block" },
                        }}
                      >
                        <img
                          srcSet={`${asset.imageUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
                          src={`${asset.imageUrl}?w=248&fit=crop&auto=format`}
                          alt={asset.createdAt}
                          loading="lazy"
                          onClick={e => handleConfirmation(e, asset)}
                          style={{
                            opacity: selectedAssetsList.includes(asset)
                              ? 0.6
                              : 1,
                            borderRadius: selectedAssetsList.includes(asset)
                              ? "50%"
                              : "0",
                            border: selectedAssetsList.includes(asset)
                              ? `5px solid rgba(255, 0, 0, 1)`
                              : "none",
                          }}
                        />
                        <IconButton
                          onClick={e => handleToggleSelection(e, asset)}
                          color="primary"
                          size="small"
                          sx={{
                            position: "absolute",
                            bottom: 3,
                            right: 3,
                            display: "none",
                          }}
                          className="selection-icon"
                        >
                          {selectedAssetsList.includes(asset) ? (
                            <CheckBoxIcon />
                          ) : (
                            <CheckBoxOutlineBlankIcon />
                          )}
                        </IconButton>
                      </ImageListItem>
                    ))}
                  </ImageList>
                )}
              </DialogContent>
              <DialogActions>
                {selectedAssetsList.length > 0 && (
                  <Button onClick={handleDeleteSelected} color="error">
                    {t("text.deleteSelected")}
                  </Button>
                )}
                <Button onClick={handleConfirmation} color="primary">
                  {t("text.fromComputer")}
                </Button>
                <Button onClick={handleClose} color="secondary">
                  {t("text.cancel")}
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      )}
    </>
  );
};

export default ImagePicker;
