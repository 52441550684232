import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { PageContainer } from "@toolpad/core/PageContainer";
import React from "react";
import { useTranslation } from "react-i18next";
import log from "../Tools/Log";

function LearningCenter() {
  const { t } = useTranslation();

  const handleClearAllLocalStorage = () => {
    localStorage.clear();
    localStorage.removeItem("isWizardOpen_Dashboard");
    localStorage.removeItem("isWizardOpen_Books");
    localStorage.removeItem("isWizardOpen_Book");

    log.warn(
      "All tutorials have been reset!",
      localStorage.getItem("isWizardOpen_Dashboard")
    );
    alert("All tutorials have been reset!");
  };

  return (
    <PageContainer title={t("text.learningCenter")} breadcrumbs="">
      <Box component="section" sx={{ pb: 2 }}>
        <Stack direction="row" spacing={1} justifyContent="center">
          <Typography variant="subtitle2" color="warning" sx={{ pt: 1 }}>
            Want to see all Getting Started popups again? Just click
          </Typography>
          <Button variant="outlined" onClick={handleClearAllLocalStorage}>
            Reset Tutorials
          </Button>
        </Stack>
        <Typography variant="h5">Welcome to the Libell.us platform!</Typography>
        <Typography variant="body1">
          Here you will find valuable insights on how to create captivating
          stories that align with your imagination.
        </Typography>
        <Typography variant="body1">
          Remember, no AI is substitute to your creativity. We hope you'll find
          the tools helpful to turn your fantasy worlds into a book you can
          share with the world.
        </Typography>
        <Typography sx={{ pt: 2 }} variant="h6">
          Dashboard
        </Typography>
        <Typography variant="body1">
          In this screen you will see an overview of your books and supporting
          materials, as well as a high level view of your membership usage.
        </Typography>
        <Typography variant="body1">In this page you'll see:</Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Books"
              secondary="Number of books you have at works, published or not."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Text Generation"
              secondary="Total of text generations you used in the current cycle."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Image Generation"
              secondary="Total of image generations you used in the current cycle."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Audio Generation"
              secondary="Total of audio generations you used in the current cycle."
            />
          </ListItem>
        </List>
        <Typography sx={{ pt: 2 }} variant="h6">
          Books
        </Typography>
        <Typography variant="body1">
          Here you can start creating new books or edit the ones you already
          started.
        </Typography>
        <List>
          <ListItemButton sx={{ color: "rgb(163, 199, 237)" }}>
            <ListItemText color="secondary" primary="Creating a new book" />
          </ListItemButton>
          <ListItemButton sx={{ color: "rgba(163, 199, 237)" }}>
            <ListItemText primary="Editting a book details" />
          </ListItemButton>
          <ListItemButton sx={{ color: "rgba(163, 199, 237)" }}>
            <ListItemText primary="Sharing a book" />
          </ListItemButton>
          <ListItemButton sx={{ color: "rgba(163, 199, 237)" }}>
            <ListItemText primary="Deleting a book" />
          </ListItemButton>
        </List>
        <Typography sx={{ pt: 2 }} variant="h6">
          User Feedback
        </Typography>
        <Typography variant="body1">
          Check out what the community is saying about your books and how they
          are performing.
        </Typography>
        <Typography sx={{ pt: 2 }} variant="h6">
          Traffic
        </Typography>
        <Typography variant="body1">
          In this screen you can see how many people are reading your books and
          how they are perfoming in the catalog.
        </Typography>
        <Typography sx={{ pt: 2 }} variant="h6">
          Membership
        </Typography>
        <Typography variant="body1">
          Here you can see your current membership status, usage and when your
          resources refreshes.
        </Typography>
        <Typography sx={{ pt: 2 }} variant="h6">
          Profile
        </Typography>
        <Typography variant="body1">
          View your public profile and update your personal information.
        </Typography>
      </Box>
    </PageContainer>
  );
}

export default LearningCenter;
