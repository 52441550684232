import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SevereColdIcon from "@mui/icons-material/SevereCold";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { logEvent } from "../../Services/firebase";
import { requestHeaders, truncateIfNeeded } from "../../Tools/DataUtils";
import {
  handleActionException,
  handleDataFetchException,
} from "../../Tools/ErrorHandling";
import log from "../../Tools/Log";
import ConfirmationDialog from "../../UiComponents/ConfirmationDialog";

function Locations() {
  const eventSource = "Locations";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [bookId] = useState(pageParams.id);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteDialogConfirmationData, setDeleteDialogConfirmationData] =
    useState({});
  const [locations, setLocations] = useState([]);

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    fetchData();
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      //TODO replace with implementation later
      const filters = {
        title: "search*",
      };

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/getLocations`,
            {
              bookUuid: pageParams.id,
              filters,
            },
            await requestHeaders()
          )
          .then(response => {
            log.trace("fetchData.response.data", response.data);
            logEvent(eventSource, "fetchData.response");

            response.data.map(item => {
              item.imageUrl =
                `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                item.backgroundImage;
            });

            setLocations(response.data);
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleDataFetchException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  const handleOpenDeleteDialog = () => {
    log.trace("handleOpenDeleteDialog");
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    log.trace("handleCloseDeleteDialog");
    setOpenDeleteDialog(false);
    setDeleteDialogConfirmationData({});
  };

  const handleConfirmDeleteLocationDialog = async () => {
    log.trace("handleConfirmDeleteLocationDialog.request");
    logEvent(eventSource, "handleConfirmDeleteLocationDialog.request");

    handleCloseDeleteDialog();

    try {
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/deleteLocation`,
            {
              bookUuid: bookId,
              locationUuid: deleteDialogConfirmationData.uuid,
            },
            headers
          )
          .then(response => {
            log.trace(
              "handleConfirmDeleteLocationDialog.response.data",
              response.data
            );
            logEvent(eventSource, "handleConfirmDeleteLocationDialog.response");
          })
      );

      await Promise.all(requests).finally(() => {
        setDeleteDialogConfirmationData({});
        hideLoadingUI();
        fetchData();
      });
    } catch (exception) {
      const response = handleActionException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  const handleCreateClick = () => {
    log.trace("handleCreateClick");
    navigate("/console/books/" + bookId + "/locations/new/edit");
  };

  const handleEditClick = (event, locationId) => {
    log.trace("handleEditClick", event, locationId);
    navigate("/console/books/" + bookId + "/locations/" + locationId + "/edit");
  };

  const handleDeleteClick = async (event, locationId, locationName) => {
    log.trace("handleDeleteClick", event, locationId, locationName);
    setDeleteDialogConfirmationData({ uuid: locationId, title: locationName });
    handleOpenDeleteDialog();
  };

  return (
    <PageContainer
      title=""
      breadcrumbs={[
        { title: t("text.overview"), path: `/console/books/${pageParams.id}` },
        {
          title: t("text.locations"),
          path: `/console/books/${pageParams.id}/locations`,
        },
      ]}
    >
      <Box component="section">
        {alertMessage.message && (
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        )}
        {loading ? (
          <LinearProgress />
        ) : (
          <Box component="section" sx={{ p: 2 }}>
            <ConfirmationDialog
              open={openDeleteDialog}
              onClose={handleCloseDeleteDialog}
              onConfirm={handleConfirmDeleteLocationDialog}
              title={t("deleteConfirmationDialog.title")}
              message={t("deleteConfirmationDialog.location.message")}
              promptHelperText={t(
                "deleteConfirmationDialog.location.helperText"
              )}
              confirmationData={deleteDialogConfirmationData}
            />
            <Grid
              container
              rowSpacing={1}
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid size={3}>
                <Button
                  onClick={handleCreateClick}
                  variant="text"
                  sx={{ width: "100%", height: "100%" }}
                >
                  <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AddCircleOutlineOutlinedIcon />
                    {t("text.createNew").toLocaleUpperCase()}
                  </Stack>
                </Button>
              </Grid>
              {locations.map(location => (
                <Grid size={3} key={location.uuid}>
                  <Card
                    sx={{
                      maxWidth: 345,
                      height: 345,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardMedia
                      sx={{ height: 140, position: "relative" }}
                      image={location.imageUrl}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          width: "100%",
                          minHeight: 40,
                          backgroundColor: "rgba(0, 0, 0, 0.4)",
                        }}
                      ></Box>
                      <Stack
                        direction="row"
                        sx={{ position: "absolute", bottom: 8, right: 8 }}
                      >
                        {location.ambiance?.length > 0 && (
                          <Tooltip
                            title={location.ambiance
                              .map(amb =>
                                t(
                                  `view.locationAmbianceSetupDialog.text.${amb.key}`
                                )
                              )
                              .join(", ")}
                          >
                            <SevereColdIcon
                              sx={{ color: "white" }}
                              size="small"
                            />
                          </Tooltip>
                        )}
                      </Stack>
                    </CardMedia>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {location.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        {truncateIfNeeded(location.backstory)}
                      </Typography>
                    </CardContent>

                    <CardActions sx={{ mt: "auto" }}>
                      <IconButton
                        onClick={e => handleEditClick(e, location.uuid)}
                        color="primary"
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={e =>
                          handleDeleteClick(e, location.uuid, location.name)
                        }
                        color="primary"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

export default Locations;
