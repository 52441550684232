import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";

const PageInfoDrawer = ({ open, onClose, onUpdate, pageData }) => {
  const eventSource = "PageInfoDrawer";

  const { t } = useTranslation();
  const drawerWidth = 300;

  const [page, setPage] = useState({});

  useEffect(() => {
    log.trace("useEffect", pageData);
    logEvent(eventSource, "useEffect");

    setPage(pageData);
  }, [pageData]);

  const handleFormSubmit = async e => {
    log.trace("handleFormSubmit.request", e, page);
    logEvent(eventSource, "handleFormSubmit.request");
    e.preventDefault();
    onUpdate(page);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setPage({ ...page, [name]: value });
  };

  const handleChoiceInputChange = e => {
    log.trace("handleChoiceInputChange", e);
    const name = Number(e.target.name);
    const { value } = e.target;
    setPage({
      ...page,
      choices: {
        ...page.choices,
        [name]: { ...page.choices[name], text: value },
      },
    });
  };

  const handleCancel = () => {
    log.trace("handleCancel");
    onClose();
  };

  const handleChoiceDelete = choice => {
    log.trace("handleChoiceDelete", choice);
    const pageChoices = page.choices;
    delete pageChoices[choice.leadToPage];
    setPage({ ...page, choices: pageChoices });
  };

  return (
    <>
      {open && (
        <Drawer
          open={open}
          onClose={onClose}
          variant="persistent"
          anchor={"right"}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          {page?.pageNumber && (
            <Box
              component="form"
              onSubmit={handleFormSubmit}
              sx={{
                width: drawerWidth - 4,
                overflow: "auto",
                pt: 10,
                pl: 2,
                pr: 2,
              }}
              role="presentation"
            >
              <Typography gutterBottom variant="h5">
                #{page.pageNumber}
              </Typography>

              <TextField
                id="ideation"
                name="ideation"
                required
                autoFocus
                fullWidth
                multiline
                maxRows={20}
                value={page.ideation}
                onChange={handleInputChange}
                label={t("text.ideation")}
                variant="outlined"
                sx={{ mb: 2 }}
              />

              {page.choices &&
                Object.values(page.choices)
                  .sort((a, b) => a.order - b.order)
                  .map(choice => {
                    return (
                      <Stack
                        direction="column"
                        spacing={2}
                        sx={{ mb: 2 }}
                        key={choice.leadToPage.toString()}
                      >
                        <TextField
                          onChange={handleChoiceInputChange}
                          fullWidth
                          margin="dense"
                          id={choice.leadToPage.toString()}
                          name={choice.leadToPage.toString()}
                          label={t("text.choice")}
                          type="text"
                          value={choice.text}
                          variant="standard"
                        />
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography gutterBottom variant="body1">
                            {t("view.choiceDialog.text.leadToPage")}:{" "}
                            {choice.leadToPage}
                          </Typography>
                          <IconButton
                            onClick={() => handleChoiceDelete(choice)}
                            size="small"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      </Stack>
                    );
                  })}

              <Stack
                spacing={2}
                direction="row"
                sx={{ p: 2 }}
                justifyContent="center"
              >
                <Button variant="contained" type="submit" color="primary">
                  {t("buttonAction.save")}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancel}
                >
                  {t("buttonAction.cancel")}
                </Button>
              </Stack>
            </Box>
          )}
        </Drawer>
      )}
    </>
  );
};

export default PageInfoDrawer;
