import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { logEvent } from "../Services/firebase";
import { requestHeaders } from "../Tools/DataUtils";
import {
  handleActionException,
  handleDataFetchException,
} from "../Tools/ErrorHandling";
import log from "../Tools/Log";

function NewUserMembership() {
  const eventSource = "NewUserMembership";

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [userProfileData, setUserProfileData] = useState({
    displayName: "",
    email: "",
    emailVerified: "",
    penName: "",
    customerId: "",
    membership: {},
  });
  const [membershipPackages, setMembershipPackages] = useState([]);

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    fetchData();
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      requests.push(
        axios
          .post(`${process.env.REACT_APP_API_URL}/getUserProfile`, {}, headers)
          .then(response => {
            log.trace("fetchData.response.data", response.data);
            logEvent(eventSource, "fetchData.response");

            setUserProfileData(response.data);

            // page not intended for current active members
            if (
              response.data.membership &&
              response.data.membership.status === "active"
            ) {
              log.trace(
                "fetchData.activeMemberLandingOnNewUserFlow",
                response.data
              );
              logEvent(
                eventSource,
                "fetchData.activeMemberLandingOnNewUserFlow"
              );

              navigate("/console");
              return;
            }
          })
      );

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/getProductOfferings`,
            {},
            headers
          )
          .then(response => {
            log.trace("fetchData.response.data", response.data);
            logEvent(eventSource, "fetchData.response");

            setMembershipPackages(response.data);
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleDataFetchException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  const handleFreeMembership = async (e, pkg) => {
    log.trace("handleFreeMembership.request", e, pkg);
    logEvent(eventSource, "handleFreeMembership.request");

    // TODO; previous memberships should have a history; never overwritten
    try {
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/freeSubscription`,
            { subscriptionId: pkg.uuid },
            headers
          )
          .then(response => {
            log.trace("handleFreeMembership.response.data", response.data);
            logEvent(eventSource, "handleFreeMembership.response");

            navigate("/getapp");
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleActionException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  const handleSubscribe = async (e, pkg) => {
    log.trace("handleSubscribe.request", e, pkg);
    logEvent(eventSource, "handleSubscribe.request");

    try {
      e.preventDefault();
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      // pass in user & product data to make it easy to connect stripe and libell.us customers
      const payload = { package: pkg, user: userProfileData };

      // if returning customer, pass in data so we can link the new subscription to the existing customer
      // when not passing in data, a new customer will be created during checkout
      if (userProfileData.customerId) {
        payload.user.userCustomerId = userProfileData.customerId;
      }

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/commerceCheckout`,
            payload,
            headers
          )
          .then(async response => {
            log.trace("handleSubscribe.response.data", response.data);
            logEvent(eventSource, "handleSubscribe.response");

            const stripe = await loadStripe("pk_test_TQIt1ho9OTmhyM1TzN8PMTOc");
            stripe.redirectToCheckout(response.data);
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleActionException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      setAlertMessage({
        message: t(response.message),
        severity: "error",
      });

      hideLoadingUI();
    }
  };

  return (
    <Box component="section" sx={{ p: 2 }}>
      {alertMessage.message && (
        <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
      )}

      {loading ? (
        <LinearProgress />
      ) : (
        <Box component="section" sx={{ p: 2 }}>
          <Typography variant="h5" sx={{ pb: 2 }}>
            Hello {userProfileData.displayName}! Select a plan that better fits
            your creativity cravings.
          </Typography>
          <Typography variant="body1" sx={{ pb: 2 }}>
            You can cancel or resubscribe anytime with few clicks. No payment
            information is ever stored. Your data is protected by Stripe.
          </Typography>
          <Stack spacing={2} direction="row">
            {membershipPackages.map(pkg => (
              <Card
                key={pkg.uuid}
                sx={{
                  width: 345,
                  height: 550,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardMedia
                  sx={{ minHeight: 150, height: 150 }}
                  image={pkg.imageUrl}
                  title={pkg.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {pkg.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      whiteSpace: "pre-line",
                      color: "text.secondary",
                    }}
                  >
                    {pkg.description}
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                      color: "text.secondary",
                    }}
                  >
                    What you get:
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemText>
                        {pkg.aiTextGenPerCycle} AI text generations per cycle
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        {pkg.aiImageGenPerCycle} AI image generations per cycle
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        {pkg.aiAudioGenPerCycle} AI audio generations per cycle
                      </ListItemText>
                    </ListItem>
                  </List>

                  {pkg.price ? (
                    <Typography
                      multiline
                      variant="h6"
                      sx={{ color: "text.secondary" }}
                    >
                      ${pkg.price}/{pkg.period}
                    </Typography>
                  ) : (
                    <Typography
                      multiline
                      variant="h6"
                      sx={{ fontStyle: "italic", color: "text.secondary" }}
                    >
                      free
                    </Typography>
                  )}
                </CardContent>
                <CardActions sx={{ mt: "auto" }}>
                  {pkg.price ? (
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      onClick={e => handleSubscribe(e, pkg)}
                    >
                      Subscribe
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={e => handleFreeMembership(e, pkg)}
                    >
                      Get Started
                    </Button>
                  )}
                </CardActions>
              </Card>
            ))}
          </Stack>
        </Box>
      )}
    </Box>
  );
}

export default NewUserMembership;
