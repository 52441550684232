import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { logEvent } from "../Services/firebase";
import { requestHeaders } from "../Tools/DataUtils";
import { handleDataFetchException } from "../Tools/ErrorHandling";
import log from "../Tools/Log";

const AudioGenerationDialog = ({
  title,
  message,
  open,
  onClose,
  onContinue,
  optionsData,
}) => {
  const eventSource = "AudioGenerationDialog";
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState({
    voiceType: "en-US-Journey-F",
    speakingRate: 1,
    pitch: 0,
  });
  const [voiceTypeList, setVoiceTypeList] = useState({});

  const [selectedVoiceTypePreviewUrl, setSelectedVoiceTypePreviewUrl] =
    useState();

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");

    if (optionsData) {
      setOptions(optionsData);

      const firstVoiceType = optionsData.voiceType;
      setPreviewUrl(firstVoiceType);

      log.warn("useEffect.optionsData", optionsData);
    }

    if (open) {
      fetchData(i18n.language);
    }
  }, [open, optionsData]);

  const fetchData = async userLanguage => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      const requests = [];
      const headers = await requestHeaders();

      requests.push(
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/getAudioGenerationOptions`,
            { userLanguage: userLanguage },
            headers
          )
          .then(audioResponse => {
            log.trace("fetchData.response.data", audioResponse.data);
            logEvent(eventSource, "fetchData.response");

            setVoiceTypeList({
              options: audioResponse.data.sort((a, b) =>
                a.label.localeCompare(b.label)
              ),
            });

            if (optionsData == null) {
              const firstVoiceType = audioResponse.data[0];
              setPreviewUrl(firstVoiceType);

              setOptions({
                voiceType: firstVoiceType,
                speakingRate: 1,
                pitch: 0,
              });
            }
          })
      );

      await Promise.all(requests).finally(() => {
        hideLoadingUI();
      });
    } catch (exception) {
      const response = handleDataFetchException(eventSource, exception);
      hideLoadingUI();
    }
  };

  const handleConfirmation = e => {
    log.trace("handleConfirmation", e);
    onContinue(options);
  };

  const handleDialogClose = e => {
    log.trace("handleDialogClose", e);
    onClose(options);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setOptions({ ...options, [name]: value });
  };

  const handleVoiceTypeInputChange = (event, value) => {
    log.trace("handleVoiceTypeInputChange", event, value);

    // if value in voiceTypeList.options setOptions
    if (voiceTypeList.options.includes(value)) {
      setOptions({ ...options, voiceType: value });
      setPreviewUrl(value);
    }
  };

  const setPreviewUrl = voiceType => {
    // TODO FIXME enable for all supported languages
    if (i18n.language == "en-US") {
      const previewUrl =
        `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/assetsLibrary/voiceTypes/` +
        voiceType.name +
        ".mp3";
      setSelectedVoiceTypePreviewUrl(previewUrl);
    }
  };

  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = options.speakingRate;
      // options.pitch is not supported by the audio element
    }
  }, [options.speakingRate, options.pitch]);

  return (
    <>
      {open && options && (
        <Dialog open={open} onClose={handleDialogClose}>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 10 }}>
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <>
              <DialogTitle>{title}</DialogTitle>
              <DialogContent>
                <DialogContentText>{message}</DialogContentText>
                <Autocomplete
                  sx={{ p: 2 }}
                  {...voiceTypeList}
                  id="voiceType"
                  name="voiceType"
                  fullWidth
                  autoComplete
                  onChange={handleVoiceTypeInputChange}
                  value={options.voiceType}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip
                          variant="filled"
                          label={option.label}
                          key={key}
                          {...tagProps}
                        />
                      );
                    })
                  }
                  renderInput={voice => (
                    <TextField
                      {...voice}
                      key={voice.name}
                      variant="standard"
                      label={t("view.audioGenerationDialog.text.voiceType")}
                    />
                  )}
                />
                {options.voiceType.isConfigurable && (
                  <>
                    <Stack direction="row" spacing={2}>
                      <Typography
                        variant="p"
                        sx={{ textAlign: "right", width: "12em" }}
                      >
                        {t("view.audioGenerationDialog.text.speakingRate")}
                      </Typography>
                      <Slider
                        id="speakingRate"
                        name="speakingRate"
                        value={options.speakingRate}
                        onChange={handleInputChange}
                        valueLabelDisplay="auto"
                        min={0.25}
                        max={4.0}
                        step={0.05}
                      />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <Typography
                        variant="p"
                        sx={{ textAlign: "right", width: "12em" }}
                      >
                        {t("view.audioGenerationDialog.text.pitch")}
                      </Typography>
                      <Slider
                        id="pitch"
                        name="pitch"
                        value={options.pitch}
                        onChange={handleInputChange}
                        valueLabelDisplay="auto"
                        min={-0.2}
                        max={0.2}
                        step={0.005}
                      />
                    </Stack>
                  </>
                )}
              </DialogContent>
              <DialogActions>
                {selectedVoiceTypePreviewUrl && (
                  <audio
                    ref={audioRef}
                    id="audioPreview"
                    name="audioPreview"
                    controls
                    src={selectedVoiceTypePreviewUrl}
                  >
                    {t("error.noAudioSupport")}
                  </audio>
                )}
                <Button onClick={handleConfirmation} color="primary">
                  {t("buttonAction.continue")}
                </Button>
                <Button onClick={handleDialogClose} color="secondary">
                  {t("buttonAction.cancel")}
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      )}
    </>
  );
};

export default AudioGenerationDialog;
