import axios from "axios";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { logEvent } from "./Services/firebase";
import { requestHeaders } from "./Tools/DataUtils";
import { handleDataFetchException } from "./Tools/ErrorHandling";
import log from "./Tools/Log";

function CheckAccountStateInterstitial() {
  const eventSource = "CheckAccountStateInterstitial";

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      const requests = [];
      const headers = await requestHeaders();

      requests.push(
        axios
          .post(`${process.env.REACT_APP_API_URL}/checkAuth`, {}, headers)
          .then(response => {
            log.trace("fetchData.response.data", response.data);
            logEvent(eventSource, "fetchData.response");
            navigate("/console");
          })
      );

      await Promise.all(requests);
    } catch (exception) {
      const response = handleDataFetchException(eventSource, exception);
      if (response.isRedirect) {
        navigate(response.redirectUrl);
        return;
      }

      navigate("/console");

      // TODO check if needed
      // // server exception
      // firebaseAuth.signOut().then(() => {
      //   navigate("/");
      // });
    }
  };

  return <></>;
}

export default CheckAccountStateInterstitial;
